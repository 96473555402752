<template>
  <div
    style="
      background-color: white;
      border-radius: 15px;
      margin: 20px;
      width: 500px;
      padding: 20px;
      margin-left: auto;
      margin-right: auto;
    "
  >
    <img
      src="../assets/rojo.svg"
      width="300"
      height="100"
      style="margin-left: auto; margin-right: auto; display: flex"
    />
    <h3 style="text-align: center; margin-top: 15px">Bienvenido</h3>

    <div v-if="!$store.state.isLogged" style="margin-top: 15px">
      <v-btn
        width="200"
        style="
          background-color: #595959;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        v-on:click="login()"
        >Ingresar</v-btn
      >
    </div>
    <div v-else style="margin-top: 15px">
      <v-btn
        width="200"
        style="
          background-color: #595959;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        v-on:click="logout()"
        >Cerrar Sesion</v-btn
      >
    </div>
  </div>
</template>

<script>
import { PublicClientApplication } from "@azure/msal-browser";
import { defineComponent } from "vue";
import AzureService from "@/services/AzureService";
import store from "@/store/index";
//import { stripRequest } from '@azure/core-http';
//import { useAppInsights } from "vue3-application-insights";
//import axios from "axios";

export default defineComponent({
  name: "LoginAzure",
  data() {
    return {
      //account: "",
    };
  },
  async created() {
    const azureService = new AzureService();
    this.$msalInstance = new PublicClientApplication(
      azureService.getMsalConfig().value
    );

    await this.$msalInstance.initialize();
    await this.$msalInstance.handleRedirectPromise();
    this.$msalInstance.acquireTokenSilent(/* {
      scopes: ["user.read", "mail.send"],
    } */); //acquireTokenSilent();
  },

  methods: {
    async login() {
      await this.$msalInstance
        .loginPopup({ scopes: ["User.Read", "Mail.Send"] })
        .then((response) => {
          //console.log(response);

          const myAcccount = this.$msalInstance.getAllAccounts();
          store.state.account = myAcccount[0];

          //console.log(store.state.account);

          this.$emitter.emit("login", store.state.account);

          this.$cookies.set("isLogged", true);
          this.$cookies.set("token", response.accessToken);

          //console.log("token: " + response.accessToken);

          ///console.log(response.accessToken);

          this.$cookies.set("account", store.state.account);
          this.$cookies.set(
            "username",
            store.state.account.username ??
              store.state.account.preferred_username
          );

          store.state.isLogged = true;
          store.state.nombre = store.state.account.name;

          /*           localStorage.detalleBusqueda = null;
          localStorage.fecha = "";
          localStorage.empresa = null;
          localStorage.departamento = null;
          localStorage.Estado = { id: "Todos", Nombre: "Todos" };
          localStorage.items = null;
          this.solId = "";
          this.solicitantePor = null;
          this.solicitante = null;
          this.tipoSol = { id: "Todos", Nombre: "Todos" }; */

          this.$router.push({ name: "BuscaSolicitud" });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async logout() {
      store.state.nombre = "Invitado";
      store.state.isLogged = false;
      store.state.email = "";

      this.$cookies.remove("isLogged");
      this.$cookies.remove("token");
      this.$cookies.remove("account");

      /*       localStorage.detalleBusqueda = null;
      localStorage.fecha = "";
      localStorage.empresa = null;
      localStorage.departamento = null;
      localStorage.Estado = { id: "Todos", Nombre: "Todos" };
      localStorage.items = null;
      this.solId = "";
      this.solicitantePor = null;
      this.solicitante = null;
      this.tipoSol = { id: "Todos", Nombre: "Todos" }; */

      await this.$msalInstance.logout();
      this.$emitter.emit("logout");
    },
  },
});
</script>

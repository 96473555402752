import './assets/main.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Emmitter from 'tiny-emitter'

import { createApp } from 'vue'
import App from './App.vue'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import router from './router'
import store from "./store";
import VueCookies from 'vue3-cookies'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { AppInsightsPlugin } from "vue3-application-insights";
import moment from 'moment'

const vuetify = createVuetify({
    icons: {
        defaultSet: 'mdi', // This is already the default value - only for display purposes
    },
    components,
    directives,
    //VDataTable
})

const app = createApp(App)

app.config.globalProperties.$msalInstance = {}
app.config.globalProperties.$emitter = new Emmitter.TinyEmitter()



app.use(vuetify).use(router).use(store).use(VueCookies).use(VueSweetalert2).use(AppInsightsPlugin, {
    appName: "Solicitud Compra", // Prefix for route events
    connectionString: import.meta.env.VITE_APP_INSIGHTS, //"InstrumentationKey=d2ea9d3d-e0eb-4376-aa2e-05bdb41bb2e3;IngestionEndpoint=https://westus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://westus.livediagnostics.monitor.azure.com/",
    router: router,
    trackAppErrors: true,
})

app.use(VueCookies, {
    expireTimes: "1d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
}).use(moment).mount('#app');
<style>
.v-table > .v-table__wrapper > table > thead > tr > th {
  padding: 0 4px !important;
}

.swal2-confirm.swal2-styled {
  background-color: #a61a2f !important;
  box-shadow: none !important;
}

.swal2-warning {
  border-color: #a61a2f !important;
  color: #a61a2f !important;
}

.swal2-show {
  border-radius: 15px !important;
}

.v-btn__content {
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2 style="margin-bottom: 15px; font-weight: 600; border-bottom: 1px solid">
      {{ "SOC-" + code.toString().padStart(7, "0") }} - Datos Generales
    </h2>

    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label>Tipo Solicitud:</label>

        <v-text-field
          v-model="tipo"
          readonly
          pa-1
          ma-0
          hide-details
          variant="solo"
          color="primary"
          required
        />
      </div>

      <div class="w-in">
        <label>Estado Solicitud:</label>

        <v-text-field
          v-model="estado"
          readonly
          pa-1
          hide-details
          ma-0
          variant="solo"
          color="primary"
          required
        />
      </div>

      <div class="w-in">
        <label>Fecha Solicitud:</label>

        <v-text-field
          v-model="date"
          readonly
          pa-1
          hide-details
          ma-0
          variant="solo"
          color="primary"
          required
        />
      </div>

      <div class="w-in">
        <label>Rut Ingresado Por:</label>

        <v-text-field
          variant="solo"
          readonly
          pa-1
          ma-0
          v-model="solPorId"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Ingresado Por:</label>
        <v-text-field
          readonly
          variant="solo"
          pa-1
          ma-0
          v-model="solPorNombre"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Empresa:</label>

        <v-combobox
          v-model="empresaSel"
          :items="lstEmpresas"
          variant="solo"
          pa-1
          hide-details
          ma-0
          readonly
          item-title="nameDisplayName"
          item-value="id"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.nameDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Departamento:</label>

        <v-combobox
          v-model="departamentoSel"
          :items="lstDepartamento"
          variant="solo"
          readonly
          hide-details
          pa-1
          ma-0
          item-title="codeDisplayName"
          item-value="code"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Rut Solicitante:</label>

        <v-text-field
          variant="solo"
          readonly
          pa-1
          ma-0
          v-model="solId"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Solicitante:</label>
        <v-text-field
          readonly
          variant="solo"
          pa-1
          ma-0
          v-model="solNombre"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in" v-if="tipo == 'Compra'">
        <label>Proveedor:</label>

        <v-combobox
          v-model="vendorSel"
          readonly
          :items="lstVendors"
          variant="solo"
          item-title="numberDisplayName"
          item-value="id"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.numberDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in" v-if="tipo === 'Retiro Bodega'">
        <label>Cod. Bod:</label>

        <v-combobox
          variant="solo"
          :items="lstBodegas"
          hide-details
          pa-1
          ma-0
          readonly
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          v-model="bodegaSel"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div v-if="tipo == 'Retiro Bodega'" class="w-in">
        <label>Codigo Despacho:</label>

        <v-text-field
          variant="solo"
          readonly
          pa-1
          ma-0
          v-model="codigoDes"
          hide-details
        >
        </v-text-field>
      </div>

      <div v-if="tipo == 'Compra'" class="w-in">
        <label>Num. BC365:</label>

        <v-text-field
          variant="solo"
          readonly
          pa-1
          ma-0
          v-model="idBc365"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Observación:</label>

        <v-textarea
          readonly
          variant="solo"
          pa-1
          ma-0
          v-model="obs"
          hide-details
        >
        </v-textarea>
      </div>

      <div
        v-if="
          tipo == 'Rendicion' &&
          (estado == 'Rechazada' ||
            estado == 'Autorizada' ||
            estado == 'Aprobada')
        "
        class="w-in"
      >
        <label v-if="estado == 'Rechazada'">Rechazador:</label>
        <label v-if="estado == 'Autorizada'">Autorizador:</label>
        <label v-if="estado == 'Aprobada'">Aprobador:</label>

        <v-text-field
          readonly
          variant="solo"
          pa-1
          ma-0
          v-model="NombreAutorizador"
          hide-details
        >
        </v-text-field>
      </div>

      <div v-if="adjuntos.length > 0" class="w-in">
        <label>{{ adjuntos.length }} Archivos Adjuntos:</label>

        <div class="text-center d-flex flex-wrap">
          <div
            v-for="(adj, index) in adjuntos"
            :key="index"
            style="align-items: center"
          >
            <v-tooltip :text="adj.nombre">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  class="ma-2"
                  style="
                    height: 55px;
                    background-color: #a61a2f;
                    color: white;
                    border-radius: 11px;
                  "
                  @click="bajarAdjunto(sliceString(adj.url))"
                >
                  <v-icon size="x-large">{{ setIcon(adj.nombre) }}</v-icon>
                  <div>Archivo {{ index + 1 }}</div>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2 style="margin-bottom: 10px; font-weight: 600; border-bottom: 1px solid">
      Detalle Solicitud
    </h2>

    <div style="width: 100%; display: flex">
      <v-btn
        prepend-icon="mdi-export"
        v-on:click="
          bajarPdf('SOC-' + code.toString().padStart(7, '0') + '.pdf')
        "
        style="
          margin-left: auto;
          background-color: rgb(166 26 47);
          border-radius: 15px;
          color: white;
          margin-bottom: 8px;
        "
      >
        <template v-slot:prepend>
          <v-icon color="white"></v-icon>
        </template>

        Exportar
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="lstDetalle"
      v-if="tipo == 'Compra'"
    >
      <template v-slot:item.material="{ item }">
        {{ item.material }}
      </template>

      <template v-slot:item.precio="{ item }">
        {{ formatMoney(item.precio) }}
      </template>

      <template v-slot:item.total="{ item }">
        {{ formatMoney(item.total) }}
      </template>
    </v-data-table>

    <v-data-table
      :headers="headersRetiro"
      :items="lstDetalle"
      v-if="tipo == 'Retiro Bodega'"
    >
      <!-- <template v-slot:item.precio="{ item }">
        {{ formatMoney(item.precio) }}
      </template>

      <template v-slot:item.total="{ item }">
        {{ formatMoney(item.total) }}
      </template> -->

      <template v-slot:item.codigoDespacho="{ item }">
        <div v-if="item.retiroBodega && esBodeguero">
          <div v-if="getEstadoDespacho(item)">
            <v-text-field
              v-model="item.codigoDespacho"
              variant="outlined"
              density="compact"
              hide-details
              pa-1
              ma-0
            />
          </div>

          <div v-else>
            <v-text-field
              v-model="item.codigoDespacho"
              variant="outlined"
              density="compact"
              hide-details
              pa-1
              ma-0
              readonly
            />
          </div>
        </div>

        <div v-else>
          <v-text-field
            v-model="item.codigoDespacho"
            variant="outlined"
            density="compact"
            hide-details
            pa-1
            ma-0
            disabled
          />
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div v-if="item.retiroBodega && esBodeguero">
          <div v-if="getEstadoDespacho(item)">
            <v-btn
              @click="despacharItem(item)"
              style="
                background-color: #10510e;
                margin-top: 10px;
                border-radius: 15px;
                color: white;
                margin-left: 10px;
              "
              >Despachar</v-btn
            >
          </div>
        </div>
      </template>
    </v-data-table>

    <v-data-table
      v-if="tipo == 'Rendicion'"
      :headers="headersRendicion"
      :items="lstDetalle"
    >
      <template v-slot:item.fechaDoc="{ item }">
        {{ item.fechaDoc }}
      </template>

      <template v-slot:item.descGasto="{ item }">
        {{ item.descGasto }}
      </template>

      <template v-slot:item.monto="{ item }">
        {{ formatMoney(item.monto) }}
      </template>
    </v-data-table>

    <div
      v-if="
        tipo != 'Rendicion' && estado == 'Creada' && aprobador == 'Aprobador'
      "
      style="display: flex; margin-top: 10px"
    >
      <v-btn
        width="200"
        style="
          background-color: #a61a2f;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        density="default"
        @click="dialogRechazar = true"
        >Rechazar Solicitud</v-btn
      >
      <v-btn
        width="200"
        style="
          background-color: #10510e;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        density="default"
        @click="dialogAprobar = true"
        >Aprobar Solicitud</v-btn
      >
    </div>

    <div
      v-if="tipo == 'Rendicion'"
      style="
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-right: 10px;
        display: flex;
        justify-content: flex-end;
      "
    >
      Total: {{ formatMoney(total) }}
    </div>

    <div
      v-if="
        tipo == 'Rendicion' &&
        estado == 'Creada' &&
        esAutorizador &&
        aprobador == 'Autorizador'
      "
      style="display: flex; margin-top: 10px"
    >
      <v-btn
        width="200"
        style="
          background-color: #a61a2f;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        density="default"
        @click="dialogRechazar = true"
        >Rechazar Solicitud</v-btn
      >
      <v-btn
        width="200"
        style="
          background-color: #10510e;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        density="default"
        @click="dialogAutorizar = true"
        >Autorizar Solicitud</v-btn
      >
    </div>

    <div style="display: flex; margin-top: 10px">
      <v-btn
        style="
          width: 200px;
          background-color: #10510e;
          padding: 15px;
          margin-top: 10px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          display: flex;
        "
        :enabled="enabledBC365"
        v-if="esComprador && estado == 'Aprobada'"
        @click="enviarBusinessCentral()"
      >
        Enviar a BC365
      </v-btn>

      <v-btn
        style="
          margin-top: 10px;
          width: 200px;
          background-color: #595959;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          display: flex;
        "
        v-if="estado == 'Rechazada'"
        @click="navigate()"
      >
        Copiar Solicitud
      </v-btn>
    </div>

    <div style="display: flex; margin-top: 10px; justify-content: end">
      <div
        style="display: flex; margin-top: 10px; margin-right: 10px"
        v-if="
          (esAdmin || esComprador || esBodeguero || esContabilidad) &&
          (estado == 'Aprobada' || estado == 'Autorizada')
          // && nulleable
        "
      >
        <v-btn
          style="
            background-color: #575757;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-left: auto;
            display: flex;
          "
          @click="anularSolicitud()"
        >
          Anular
        </v-btn>
      </div>

      <div style="display: flex; margin-top: 10px">
        <v-btn
          style="
            background-color: #a61a2f;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-left: auto;
            display: flex;
          "
          v-if="esBodeguero && estado == 'En Despacho'"
          @click="dialogCerrar = true"
        >
          Cerrar Solicitud
        </v-btn>

        <v-btn
          style="
            width: 200px;
            background-color: #10510e;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-left: 10px;
            display: flex;
          "
          v-if="esBodeguero"
          @click="dialogDespachar = true"
        >
          Despachar Solicitud
        </v-btn>
      </div>

      <div
        v-if="estado == 'Autorizada' && esContabilidad"
        style="margin-top: 10px"
      >
        <v-btn
          style="
            width: 200px;
            background-color: #10510e;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-left: auto;
            display: flex;
          "
          @click="enviarRendicion()"
        >
          Enviar Rendición
        </v-btn>
      </div>
    </div>
  </div>

  <v-row justify="center">
    <v-dialog v-model="dialogRechazar" persistent width="50%">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >¿Esta seguro que desea rechazar esta solicitud?</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-textarea
                variant="solo"
                v-model="motivo"
                label="Motivo del rechazo*"
                required
              ></v-textarea>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogRechazar = false"
          >
            No!
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="rechazarSolicitud()"
          >
            Si, rechazar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-row justify="center">
    <v-dialog v-model="dialogAprobar" persistent width="50%">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >¿Esta seguro que desea aprobar esta solicitud?</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-textarea
                variant="solo"
                v-model="motivo"
                label="Motivo del apruebo*"
                required
              ></v-textarea>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogAprobar = false"
          >
            No!
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="aprobarSolicitud('Aprobada')"
          >
            Si, aprobar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-row justify="center">
    <v-dialog v-model="dialogAutorizar" persistent width="50%">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >¿Esta seguro que desea autorizar esta solicitud?</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-textarea
                variant="solo"
                v-model="motivo"
                label="Motivo de la autorizacion*"
                required
              ></v-textarea>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogAutorizar = false"
          >
            No!
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="aprobarSolicitud('Autorizada')"
          >
            Si, autorizar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-row justify="center">
    <v-dialog v-model="dialogDespachar" persistent width="50%">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >¿Esta seguro que desea despachar esta solicitud?</span
          >
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogDespachar = false"
          >
            No!
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="despacharSolicitud()"
          >
            Si, despachar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-row justify="center">
    <v-dialog v-model="dialogCerrar" persistent width="50%">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >¿Esta seguro que desea cerrar esta solicitud?</span
          >
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogCerrar = false"
          >
            No!
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="cerrarDespachado()"
          >
            Si!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <loading v-model:active="isLoading" :is-full-page="fullPage" />
</template>

<script>
import store from "../store/index.js";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { PublicClientApplication } from "@azure/msal-browser";
import AzureService from "@/services/AzureService";

export default {
  name: "DetalleSolicitud",
  components: {
    Loading,
  },
  data() {
    return {
      date: "",
      solPorId: "",
      solPorNombre: "",
      lstEmpresas: [],
      empresaSel: null,
      lstDepartamento: [],
      departamentoSel: null,
      solId: "",
      solNombre: "",
      obs: "",
      code: "",

      lstDetalle: [],
      headers: [
        { title: "Id", key: "id", align: "center", width: "25px" },
        { title: "Tipo", key: "tipo", align: "start", width: "100px" },
        {
          title: "Cod",
          key: "codigoMaterial",
          align: "center",
          width: "150px",
        },
        {
          title: "Material/Servicio",
          key: "material",
          align: "center",
          width: "100px",
        },
        { title: "UN", key: "unidad", align: "center", width: "90px" },
        { title: "Q", key: "cantidad", align: "center", width: "100px" },
        { title: "Precio", key: "precio", align: "center", width: "100px" },
        { title: "Total", key: "total", align: "center", width: "100px" },
        { title: "Ceco", key: "cecoId", align: "start", width: "150px" },
        {
          title: "Proyecto",
          key: "proyectoId",
          align: "center",
          width: "150px",
        },
        {
          title: "Bodega",
          key: "codigoBodega",
          align: "center",
          width: "100px",
        },
        {
          title: "OT Mantención",
          key: "otMantencion",
          align: "center",
          width: "50px",
        },
      ],

      headersRetiro: [
        { title: "Id", key: "id", align: "center", width: "25px" },
        { title: "Tipo", key: "tipo", align: "start", width: "100px" },
        {
          title: "Cod",
          key: "codigoMaterial",
          align: "center",
          width: "150px",
        },
        {
          title: "Material/Servicio",
          key: "material",
          align: "start",
          width: "100px",
        },
        { title: "UN", key: "unidad", align: "center", width: "90px" },
        { title: "Q", key: "cantidad", align: "center", width: "100px" },
        { title: "Ceco", key: "cecoId", align: "start", width: "100px" },
        {
          title: "Proyecto",
          key: "proyectoId",
          align: "center",
          width: "200px",
        },
        {
          title: "OT Mantención",
          key: "otMantencion",
          align: "center",
          width: "50px",
        },
        {
          title: "Observación",
          key: "codigoDespacho",
          align: "center",
          width: "100px",
        },

        { title: "", key: "actions", align: "center", width: "100px" },
      ],

      headersRendicion: [
        { title: "Id", key: "id", width: "25px" },
        { title: "Tipo Docto", key: "tipoDoc", align: "center" },
        { title: "N° Docto", key: "nroDoc", align: "center" },
        { title: "Fecha Docto", key: "fechaDoc", align: "center" },
        { title: "Proveedor", key: "proveedorId", align: "center" },
        { title: "Material/Servicio", key: "material", align: "center" },
        { title: "Descripción del Gasto", key: "descGasto", align: "center" },
        { title: "Ceco", key: "cecoId", align: "center", width: "250px" },
        //{ title: "Depto", key: "DeptoId", align: "center", width: "250px" },
        {
          title: "Proyecto",
          key: "proyectoId",
          align: "center",
          width: "250px",
        },
        { title: "Monto", key: "monto", align: "center", width: "150px" },
        {
          title: "OT Mantención",
          key: "otMantencion",
          align: "center",
          width: "50px",
        },
      ],

      lstCeco: [],
      cecoSel: null,

      loading: false,
      lstItems: [],
      item: {
        number: "",
        displayName: "",
      },
      guid: "",
      file: {},
      fileName: "",

      lstBodegas: [],
      bodegaSel: null,
      detContador: 1,
      dialogDelete: false,

      isLoading: false,
      fullPage: true,
      adjunto: "",
      adjuntos: [],

      lstVendors: [],
      vendorSel: null,
      idSolicitud: "",
      estado: "",
      tipo: "",
      dialogRechazar: false,
      dialogAprobar: false,
      dialogAutorizar: false,
      dialogDespachar: false,
      dialogCerrar: false,
      codigoDespacho: "",

      motivo: "",
      rol: "",
      aprobador: "",

      //esAprobador: false,
      esComprador: false,
      esBodeguero: false,
      esContabilidad: false,
      esAutorizador: false,
      esAdmin: false,

      lstProyectos: [],
      proyectoSel: null,

      codigoDes: "",
      idBc365: "",
      total: 0,
      autorizador: "",
      lstSolicitantes: [],
      NombreAutorizador: "",
      codigoBodega: null,
      lstDimensions: [],
      nulleable: false,
      enabledBC365: true,
    };
  },

  methods: {
    getDepartamentos: async function () {
      try {
        //console.log(this.empresaSel);

        await axios
          .get(
            store.state.url +
              "api/Departamento/GetDepartamentos?company=" +
              this.empresaSel.id,
            {
              headers: {},
            }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstDepartamento = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getCompanies: function () {
      try {
        axios
          .get(store.state.url + "api/Company/GetCompanies", { headers: {} })
          .then(async (response) => {
            if (response.status == 200) {
              this.lstEmpresas = response.data;

              //await this.getDepartamentos();

              this.isLoading = true;
              this.getSolicitudCompra();
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getCeco: async function () {
      try {
        await axios
          .get(
            store.state.url + "api/Ceco/GetCecos?company=" + this.empresaSel.id,
            { headers: {} }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstCeco = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getBodegas: async function () {
      try {
        await axios
          .get(
            store.state.url +
              "api/Location/GetLocations?company=" +
              this.empresaSel.id,
            { headers: {} }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstBodegas = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getVendors: async function () {
      try {
        await axios
          .get(
            store.state.url +
              "api/Vendors/GetVendors?company=" +
              this.empresaSel.id,
            { headers: {} }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstVendors = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getProyectos: async function () {
      try {
        await axios
          .get(
            store.state.url +
              "api/Proyecto/GetProyectos?company=" +
              this.empresaSel.id,
            { headers: {} }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstProyectos = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getSolicitantes: async function () {
      try {
        await axios
          .get(store.state.url + "api/Empleado/GetEmpleados", { headers: {} })
          .then((response) => {
            if (response.status == 200) {
              this.lstSolicitantes = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getSolicitudCompra: function () {
      try {
        axios
          .get(
            store.state.url +
              "api/SolicitudCompra/GetSolicitudCompra?solicitudId=" +
              this.$route.params.solicitudId
          )
          .then(async (response) => {
            if (response.status == 200) {
              var solicitud = response.data;

              this.idSolicitud = solicitud.encabezado.id;
              this.date = solicitud.encabezado.fechaSolicitud.substr(0, 10);
              this.solPorId = solicitud.encabezado.solicitantePorId;
              this.solPorNombre = solicitud.encabezado.solicitantePorNombre;
              this.code = solicitud.encabezado.code;

              this.empresaSel = this.lstEmpresas.find(
                (r) => r.id == solicitud.encabezado.companyId
              );

              await this.getDepartamentos();
              await this.getCeco();
              await this.getBodegas();
              await this.getVendors();
              await this.getProyectos();
              await this.getSolicitantes();
              await this.getDimensionsLines();

              await this.obtenerEmpleado(this.$cookies.get("username"));
              //await this.obtenerEmpleado("eherrera@oxxeanchile.cl");

              this.departamentoSel = this.lstDepartamento.find(
                (d) => d.id == solicitud.encabezado.departamentoId
              );

              this.solId = solicitud.encabezado.solicitanteId;
              this.solNombre = solicitud.encabezado.solicitanteNombre;
              this.obs = solicitud.encabezado.observacion;
              //this.adjunto = solicitud.encabezado.archivoAdjunto;

              this.adjuntos = await this.getFiles();
              //console.log(adjuntos);

              this.vendorSel = this.lstVendors.find(
                (v) => v.id == solicitud.encabezado.vendorId
              );
              this.estado = solicitud.encabezado.estado;
              this.tipo = solicitud.encabezado.tipoSolicitudId;

              if (
                (this.estado == "Autorizada" ||
                  this.estado == "Aprobada" ||
                  this.estado == "Rechazada") &&
                solicitud.encabezado.autorizadorId != null
              ) {
                this.NombreAutorizador = this.lstSolicitantes.find(
                  (s) => s.rut == solicitud.encabezado.autorizadorId
                ).fullName;
              }

              if (this.tipo == "Retiro Bodega") {
                this.codigoDes = solicitud.encabezado.codigoDespacho;

                this.bodegaSel = this.lstBodegas.find(
                  (b) => b.id == solicitud.encabezado.codigoBodega
                );
              }

              if (this.tipo == "Compra") {
                this.idBc365 = solicitud.encabezado.bC365Id;

                this.bodegaSel = this.lstBodegas.find(
                  (b) => b.id == solicitud.encabezado.codigoBodega
                );
              }

              // if (solicitud.encabezado.proyectoId != null) {
              //   this.proyectoSel = this.lstProyectos.find(
              //     (p) => p.id == solicitud.encabezado.proyectoId
              //   );
              // }

              for (let i = 0; i < solicitud.detalle.length; i++) {
                solicitud.detalle[i].cecoId = this.lstCeco.find(
                  (c) => c.id == solicitud.detalle[i].cecoId
                ).displayName;

                if (this.tipo == "Retiro Bodega" || this.tipo == "Compra") {
                  if (solicitud.detalle[i].proyectoId != null) {
                    solicitud.detalle[i].proyectoId = this.lstProyectos.find(
                      (p) => p.id == solicitud.detalle[i].proyectoId
                    ).displayName;
                  }

                  if (solicitud.detalle[i].codigoBodega != null) {
                    solicitud.detalle[i].codigoBodega = this.lstBodegas.find(
                      (b) => b.id == solicitud.detalle[i].codigoBodega
                    ).displayName;
                  }

                  solicitud.detalle[i].codigoDespacho =
                    solicitud.detalle[i].codigoDespacho == null
                      ? ""
                      : solicitud.detalle[i].codigoDespacho;
                } else {
                  if (this.tipo == "Rendicion") {
                    if (solicitud.detalle[i].fechaDoc != null) {
                      solicitud.detalle[i].fechaDoc = solicitud.detalle[
                        i
                      ].fechaDoc.substr(0, 10);
                    }

                    if (solicitud.detalle[i].proveedorId != null) {
                      var prov = this.lstVendors.find(
                        (v) => v.id == solicitud.detalle[i].proveedorId
                      );

                      if (prov != null) {
                        solicitud.detalle[i].proveedorId = prov.displayName;
                      } else {
                        solicitud.detalle[i].proveedorId = "";
                      }
                    }

                    if (solicitud.detalle[i].proyectoId != null) {
                      solicitud.detalle[i].proyectoId = this.lstProyectos.find(
                        (p) => p.id == solicitud.detalle[i].proyectoId
                      ).displayName;
                    }
                  }
                }
              }

              this.lstDetalle = solicitud.detalle;

              // if (this.tipo == "Retiro Bodega") {
              //   var noDespachados = this.lstDetalle.filter(
              //     (i) => i.codigoBodega == null
              //   );

              //   if (noDespachados.length == this.lstDetalle.length) {
              //     this.nulleable = true;
              //   } else {
              //     this.nulleable = false;
              //   }
              // }

              this.RendicionTotal();

              var rolDepto = await this.getRolDepto(
                this.$cookies.get("username"),
                this.departamentoSel.code
              );

              /*               var rolDepto = await this.getRolDepto(
                "eherrera@oxxeanchile.cl",
                this.departamentoSel.code
              );
 */
              if (rolDepto != null) {
                if (
                  rolDepto.some((r) => r.rolNombre === "Aprobador") &&
                  this.tipo != "Rendicion"
                ) {
                  this.aprobador = "Aprobador";
                } else {
                  if (
                    rolDepto.some(
                      (r) =>
                        r.rolNombre === "Autorizador" &&
                        this.tipo == "Rendicion"
                    )
                  ) {
                    this.aprobador = "Autorizador";
                  }
                }
              }

              var roles = await this.getRol(this.$cookies.get("username"));
              //var roles = await this.getRol("eherrera@oxxeanchile.cl");

              if (roles.some((r) => r.rolNombre === "Administrator")) {
                this.esAdmin = true;
              }

              if (this.tipo == "Compra") {
                if (
                  this.estado == "Aprobada" &&
                  roles.some((r) => r.rolNombre === "Comprador")
                ) {
                  this.esComprador = true;
                }
              } else {
                if (this.tipo == "Retiro Bodega") {
                  if (
                    this.estado == "Aprobada" &&
                    roles.some((r) => r.rolNombre === "Bodega")
                  ) {
                    this.esBodeguero = true;
                  }

                  if (
                    this.estado == "En Despacho" &&
                    roles.some((r) => r.rolNombre === "Bodega")
                  ) {
                    this.esBodeguero = true;
                  }
                } else {
                  if (this.tipo == "Rendicion") {
                    if (
                      this.estado == "Creada" &&
                      roles.some((r) => r.rolNombre === "Autorizador")
                    ) {
                      this.esAutorizador = true;
                    } else {
                      if (
                        this.estado == "Autorizada" &&
                        roles.some((r) => r.rolNombre === "Contabilidad")
                      ) {
                        this.esContabilidad = true;
                      }
                    }
                  }
                }
              }

              //console.log(JSON.stringify(roles));
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } catch (error) {
        console.log(error);
      }
    },

    enviarBusinessCentral: async function () {
      try {
        this.enabledBC365 = false;

        var purchaseOrdersBody = {
          orderDate: this.date,
          postingDate: this.date,
          vendorId: this.vendorSel.id,
          vendorNumber: this.vendorSel.number,
          payToVendorId: this.vendorSel.id,
          payToVendorNumber: this.vendorSel.number,
          // // vendorId: "",
          // // vendorNumber: "",
          // // payToVendorId: "",
          // // payToVendorNumber: "",

          buyFromAddressLine1: "",
          buyFromAddressLine2: "",
          buyFromCity: "",
          buyFromCountry: "",

          currencyId: "00000000-0000-0000-0000-000000000000",
          currencyCode: "CLP",
          pricesIncludeTax: false,
          paymentTermsId: "fdba72ad-6872-ee11-817a-000d3ac19411",
          shipmentMethodId: "00000000-0000-0000-0000-000000000000",
          requestedReceiptDate: "0001-01-01",
          discountAmount: 0,
          fullyReceived: false,
          //campos para rellenar
          shipToCity: "",
          shipToName: "",
          shipToState: "",
          buyFromState: "",
          shipToContact: "",
          shipToCountry: "",
          shipToPostCode: "",
          buyFromPostCode: "",
          shipToAddressLine1: "",
          shipToAddressLine2: "",
          shortcutDimension1Code: "",
          shortcutDimension2Code: "",
          purchaser: "",
          //
          purchaseOrderLines: [],
          dimensionSetLines: [],
        };

        for (var det of this.lstDetalle.filter(
          (d) => d.retiroBodega == false
        )) {
          var ceco = this.lstCeco.find((c) => c.displayName == det.cecoId);

          var lines = {
            itemId: det.idItem,
            quantity: parseInt(det.cantidad),
            dimensionSetLines: [
              {
                id: this.lstDimensions.filter(
                  (d) => d.dimensionName == "CECO"
                )[0].dimensionId, //store.state.dimensionCeco,
                valueId: ceco.id,
              },
              {
                id: this.lstDimensions.filter(
                  (d) => d.dimensionName == "DPTO"
                )[0].dimensionId, //store.state.dimensionDepto,
                valueId: this.departamentoSel.id,
              },
            ],
          };

          if (this.proyectoSel != null) {
            lines.dimensionSetLines.push({
              id: this.lstDimensions.filter(
                (d) => d.dimensionName == "PROYECTO"
              )[0].dimensionId, //store.state.dimensionProyecto,
              valueId: this.proyectoSel.id,
            });
          }

          purchaseOrdersBody.purchaseOrderLines.push(lines);

          purchaseOrdersBody.dimensionSetLines.push({
            id: store.state.dimensionCeco,
            valueId: ceco.id,
          });
        }

        purchaseOrdersBody.dimensionSetLines.push({
          id: store.state.dimensionDepto,
          valueId: this.departamentoSel.id,
        });

        if (this.proyectoSel != null) {
          purchaseOrdersBody.dimensionSetLines.push({
            id: store.state.dimensionProyecto,
            valueId: this.proyectoSel.id,
          });
        }

        this.isLoading = true;

        await axios
          .post(
            store.state.url + "api/SolicitudCompra/PostPurchaseOrder",
            purchaseOrdersBody
          )
          .then((response) => {
            if (response.status == 200 && response.data.number != null) {
              this.UpdateBC365(response.data.number);
              this.updateEnviado();

              this.estado == "Enviada";
              this.$router.push({ name: "BuscaSolicitud" });
            } else {
              console.log("error al enviar: " + response.data.error.message);

              this.$swal.fire({
                icon: "error",
                title: "Error al enviar a Business Central",
                text: response.data.error.message,
              });
            }
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
          .finally(() => {
            this.dialogAprobar = false;
            this.isLoading = false;
            this.enabledBC365 = true;
          });
      } catch (error) {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      } finally {
        this.isLoading = false;
        this.enabledBC365 = true;
      }
    },

    enviarRendicion: async function () {
      var errorMsg = [];
      var enviadas = [];

      this.enabledBC365 = false;
      this.isLoading = true;

      await axios
        .post(
          store.state.url +
            "api/Rendicion/EnviarRendicion?rendicionId=" +
            this.idSolicitud +
            "&company=" +
            this.empresaSel.id,
          this.selectedSol
        )
        .then(async (response) => {
          if (response.status == 200) {
            for (var res of response.data) {
              if (res.message.includes("error")) {
                errorMsg.push(res.message + "\n\n");
              } else {
                enviadas.push(res.solicitudId);
              }
            }

            if (errorMsg.length > 0) {
              this.$swal.fire({
                icon: "error",
                title:
                  "Error al enviar " +
                  errorMsg.length +
                  " solicitudes a Business Central",
                text: errorMsg.join(),
              });
            } else {
              console.log("Solicitudes enviadas correctamente");

              this.estado = "Enviada";

              this.$swal.fire({
                icon: "success",
                title: "Rendiciones enviadas correctamente",
                text:
                  "Se enviaron " +
                  enviadas.length +
                  " detalle(s) de la solicitud SOC-" +
                  this.code.toString().padStart(7, "0"),
              });

              this.$router.push({ name: "BuscaSolicitud" });
            }
          } else {
            console.log(response.statusText);
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => {
          this.isLoading = false;
          this.enabledBC365 = true;
        });
    },

    despacharSolicitud: function () {
      try {
        //verify if item must have at least one despacho

        if (this.lstDetalle.some((d) => d.codigoDespacho != "") == false) {
          this.dialogDespachar = false;

          this.$swal.fire({
            icon: "error",
            title: "Error al despachar",
            text: "Debe ingresar al menos un codigo de despacho",
          });

          return;
        }

        // console.log(JSON.stringify(this.lstDetalle.filter((d) => d.codigoDespacho == "")));

        var despachados = this.lstDetalle.filter((d) => d.codigoDespacho != "");

        //console.log(JSON.stringify(despachados));

        axios
          .put(
            store.state.url +
              "api/SolicitudCompra/DespacharSolicitud?solId=" +
              this.idSolicitud,
            JSON.stringify(despachados),
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            if (response.status == 200) {
              this.estado = response.data;
              console.log("Solicitud " + response.data);

              this.dialogDespachar = false;

              this.$swal.fire({
                icon: "success",
                title: "Solicitud " + response.data,
                showConfirmButton: false,
                timer: 2000,
              });

              //this.getSolicitudCompra();
              this.$router.push({ name: "BuscaSolicitud" });
            }
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error(error);
          });
      } catch (error) {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      }
    },

    despacharItem: function (item) {
      if (item.codigoDespacho == "") {
        this.$swal.fire({
          icon: "error",
          title: "Error al despachar",
          text: "Debe ingresar un codigo de despacho",
        });

        return;
      }

      try {
        axios
          .put(
            store.state.url +
              "api/SolicitudCompra/DespacharItem?id=" +
              item.id +
              "&encabezadoId=" +
              item.encabezadoId +
              "&codDespacho=" +
              item.codigoDespacho
          )
          .then((response) => {
            if (response.status == 200) {
              var res = response.data;

              if (res == "Despachado") {
                item.estadoDespacho = "Despachado";
                this.estado = "Despachado";

                this.$router.push({ name: "BuscaSolicitud" });
              } else {
                item.estadoDespacho = "Despachado";
                this.estado = "En Despacho";
              }

              console.log("Item despachado Exitosamente");

              this.$swal.fire({
                icon: "success",
                title: "Item despachado Exitosamente",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error(error);
          })
          .finally(() => {
            this.dialogRechazar = false;
            this.motivo = "";
          });
      } catch (error) {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      } finally {
        this.isLoading = false;
      }
    },

    cerrarDespachado: function () {
      try {
        axios
          .put(
            store.state.url +
              "api/SolicitudCompra/CerrarDespachado?id=" +
              this.idSolicitud //+
            /* "&estado=Cerrada" +
              "&codDespacho=" +
              this.codigoDespacho */
          )
          .then((response) => {
            if (response.status == 200) {
              this.estado = "Cerrada";
              console.log("Solicitud Despachada Exitosamente");

              this.dialogCerrar = false;

              this.$swal.fire({
                icon: "success",
                title: "Solicitud Cerrada",
                showConfirmButton: false,
                timer: 2000,
              });

              this.$router.push({ name: "BuscaSolicitud" });
            }
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error(error);
          });
      } catch (error) {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      }
    },

    rechazarSolicitud: function () {
      try {
        axios
          .put(
            store.state.url +
              "api/SolicitudCompra/UpdateEstado?id=" +
              this.idSolicitud +
              "&estado=Rechazada" +
              "&autorizadorId=" +
              this.autorizador +
              "&motivo=" +
              this.motivo
          )
          .then((response) => {
            if (response.status == 200) {
              console.log("Solicitud Rechazada Exitosamente");
              this.estado = "Rechazada";

              this.dialogRechazar = false;

              this.sendEmail(this.idSolicitud);

              this.$swal.fire({
                icon: "success",
                title: "Solicitud Rechazada",
                showConfirmButton: false,
                timer: 2000,
              });

              this.$router.push({ name: "BuscaSolicitud" });
            }
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error(error);
          })
          .finally(() => {
            this.dialogRechazar = false;
            this.motivo = "";
          });
      } catch (error) {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      } finally {
        this.isLoading = false;
      }
    },

    aprobarSolicitud: function (estado) {
      try {
        axios
          .put(
            store.state.url +
              "api/SolicitudCompra/UpdateEstado?id=" +
              this.idSolicitud +
              "&estado=" +
              estado +
              "&autorizadorId=" +
              this.autorizador +
              "&motivo=" +
              this.motivo
          )
          .then((response) => {
            if (response.status == 200) {
              if (estado == "Autorizada") {
                this.estado = "Autorizada";
                console.log("Solicitud Autorizada Exitosamente");

                this.dialogAutorizar = false;

                this.sendEmail(this.idSolicitud);

                this.$swal.fire({
                  icon: "success",
                  title: "Solicitud Autorizada",
                  showConfirmButton: false,
                  timer: 2000,
                });
              } else {
                this.estado = "Aprobada";
                console.log("Solicitud Aprobada Exitosamente");

                this.dialogAprobar = false;

                this.sendEmail(this.idSolicitud);

                this.$swal.fire({
                  icon: "success",
                  title: "Solicitud Aprobada",
                  showConfirmButton: false,
                  timer: 2000,
                });
              }

              this.$router.push({ name: "BuscaSolicitud" });
            }
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error(error);
          });
      } catch (error) {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      }
    },

    anularSolicitud: function () {
      try {
        axios
          .put(
            store.state.url +
              "api/SolicitudCompra/UpdateEstado?id=" +
              this.idSolicitud +
              "&estado=Anulada"
          )
          .then((response) => {
            if (response.status == 200) {
              console.log("Solicitud Anulada");

              this.dialogAutorizar = false;

              this.$swal.fire({
                icon: "success",
                title: "Solicitud anulada",
                showConfirmButton: false,
                timer: 2000,
              });

              this.$router.push({ name: "BuscaSolicitud" });
            }
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error(error);
          });
      } catch (error) {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      }
    },

    updateEnviado: function () {
      try {
        axios
          .put(
            store.state.url +
              "api/SolicitudCompra/UpdateEstado?id=" +
              this.idSolicitud +
              "&estado=Enviada"
          )
          .then((response) => {
            if (response.status == 200) {
              this.estado = "Enviada";
              console.log("Solicitud Enviada a Business Central Exitosa");

              this.dialogAprobar = false;

              this.$swal.fire({
                icon: "success",
                title: "Solicitud Enviada a Business Central Exitosa",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error(error);
          });
      } catch (error) {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      }
    },

    UpdateBC365: function (idBc365) {
      try {
        axios
          .put(
            store.state.url +
              "api/SolicitudCompra/UpdateBC365?id=" +
              this.idSolicitud +
              "&idBc365=" +
              idBc365
          )
          .then((response) => {
            if (response.status == 200) {
              console.log("Id bc365 Actualizada Exitosamente");
            }
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error(error);
          });
      } catch (error) {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      }
    },

    getFiles: async function () {
      try {
        var archivos = [];

        await axios
          .get(
            store.state.url +
              "api/SolicitudCompra/GetSolicitudFiles?solicitudId=" +
              this.idSolicitud
          )
          .then((response) => {
            if (response.status == 200) {
              //console.log(response.data);
              archivos = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });

        return archivos;
      } catch (error) {
        console.log(error);
      }
    },

    sendEmail: async function (idSol) {
      try {
        await this.refreshToken()
          .then(async () => {
            var token = this.$cookies.get("token");

            await axios
              .post(
                store.state.url +
                  "api/SolicitudCompra/SendEmail?idSol=" +
                  idSol,
                token,
                { headers: { "Content-Type": "application/json" } }
              )
              .then((response) => {
                if (response.status == 200) {
                  console.log(response.data);
                } else {
                  console.log(response.statusText);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },

    refreshToken: async function () {
      try {
        await this.$msalInstance.initialize();
        //await this.$msalInstance.handleRedirectPromise();

        var redirectResponse = await this.$msalInstance.handleRedirectPromise();
        if (redirectResponse !== null) {
          this.$cookies.set("token", redirectResponse.accessToken);
        } else {
          var myAcccount = this.$msalInstance.getAllAccounts();

          await this.$msalInstance
            .acquireTokenSilent({
              scopes: ["User.Read", "Mail.Send"],
              account: myAcccount[0],
            })
            .then((response) => {
              this.$cookies.set("token", response.accessToken);
              //console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } catch (error) {
        console.log(error);
      }
    },

    bajarAdjunto: function (filename) {
      try {
        this.isLoading = true;

        const link = document.createElement("a");
        link.href =
          store.state.url +
          "api/SolicitudCompra/DownloadFile?fileName=" +
          filename;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getRol: async function (email) {
      try {
        var tiene = [];

        await axios
          .get(store.state.url + "api/Rol/GetRol?email=" + email, {
            headers: {},
          })
          .then((response) => {
            if (response.status == 200) {
              //this.tieneRol = response.data;
              //console.log(response.data);
              tiene = response.data;
            } else {
              //console.log(response.statusText);
              tiene = [];
            }
          })
          .catch(function (error) {
            console.log(error.message);
            tiene = [];
          });

        return tiene;
      } catch (error) {
        console.log(error);
      }
    },

    forceFileDownload(response, title) {
      //console.log(title);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },

    bajarPdf(title) {
      this.isLoading = true;

      var url =
        store.state.url +
        "api/SolicitudCompra/ExportarSolicitud?idSol=" +
        this.idSolicitud;

      axios({
        method: "get",
        url,
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response, title);
        })
        .catch(() => console.log("error occured"))
        .finally(() => {
          this.isLoading = false;
        });
    },

    getRolDepto: async function (email, depto) {
      try {
        var tiene = [];

        await axios
          .get(
            store.state.url +
              "api/Rol/GetRolDepto?email=" +
              email +
              "&depto=" +
              depto,
            {
              headers: {},
            }
          )
          .then((response) => {
            if (response.status == 200) {
              //this.tieneRol = response.data;
              console.log(response.data);
              tiene = response.data;
            } else {
              console.log(response.statusText);
              tiene = null;
            }
          })
          .catch(function (error) {
            console.log(error.message);
            tiene = null;
          });

        return tiene;
      } catch (error) {
        console.log(error);
      }
    },

    setIcon: function (fileName) {
      try {
        var icon = "";

        var ext = fileName.substring(fileName.lastIndexOf(".") + 1);

        switch (ext) {
          case "pdf":
            icon = "mdi-file-pdf-box";
            break;

          case "docx" || "doc":
            icon = "mdi-file-word";
            break;

          case "xlsx" || "xls":
            icon = "mdi-file-excel";
            break;

          case "pptx" || "ppt":
            icon = "mdi-file-powerpoint";
            break;

          case "jpg" || "jpeg" || "png" || "gif":
            icon = "mdi-file-image";
            break;

          case "zip" || "rar" || "7z":
            icon = "mdi-zip-box";
            break;

          case "txt":
            icon = "mdi-file-document";
            break;

          default:
            icon = "mdi-file";
            break;
        }

        console.log(icon);

        return icon;
      } catch (error) {
        console.log(error);
      }
    },

    navigate: function () {
      this.$router.push({
        name: "SolCompraParam",
        params: { solicitudId: this.idSolicitud },
      });
    },

    sliceString(value) {
      return value.substring(value.lastIndexOf("/") + 1);
    },

    CalcularTotal: function (item) {
      item.Total = item.Cantidad * item.Precio;
    },

    RendicionTotal: function () {
      this.total = this.lstDetalle.reduce(
        (acc, item) => acc + parseFloat(item.monto),
        0
      );
    },

    formatMoney: function (value) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },

    getEstadoDespacho: function (item) {
      if (item.estadoDespacho == "Despachado") {
        return false;
      } else {
        return true;
      }
    },

    obtenerEmpleado: async function (email) {
      try {
        this.isLoading = true;

        await axios
          .get(
            store.state.url + "api/Empleado/GetEmpleadoXEmail?email=" + email,
            { headers: {} }
          )
          .then(async (response) => {
            if (response.status == 200) {
              this.autorizador = response.data.rut;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getDimensionsLines: async function () {
      await axios
        .get(
          store.state.url +
            "api/SolicitudCompra/GetDimensionsId?company=" +
            this.empresaSel.id,
          {
            headers: {},
          }
        )
        .then((response) => {
          if (response.status == 200) {
            this.lstDimensions = response.data;
            // console.log(this.lstDimensions);
          } else {
            console.log(response.statusText);
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
    },
  },

  async created() {
    try {
      const azureService = new AzureService();

      this.$msalInstance = new PublicClientApplication(
        azureService.getMsalConfig().value
      );

      store.state.titulo = "Detalle Solicitud";

      await this.getCompanies();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2>Agregar Empleado</h2>

    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label>Nombre:</label>

        <v-text-field
          v-model="nombre"
          variant="solo"
          hide-details
          pa-1
          clearable
          ma-0
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Apellido:</label>

        <v-text-field
          v-model="apellido"
          variant="solo"
          hide-details
          pa-1
          clearable
          ma-0
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Rut:</label>

        <v-text-field
          v-model="rut"
          variant="solo"
          hide-details
          pa-1
          ma-0
          placeholder="12.345.678-9"
          @update:modelValue="formatearRut(item)"
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Email:</label>

        <v-text-field
          v-model="email"
          variant="solo"
          hide-details
          pa-1
          clearable
          ma-0
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Intocable:</label>

        <v-checkbox v-model="intocable" hide-details></v-checkbox>
      </div>

      <v-btn
        @click="addEmpleado"
        style="
          background-color: #a3a3a3;
          padding: 15px;
          border-radius: 15px;
          margin-top: 2.4em;
          color: white;
          display: flex;
        "
        dark
        :disabled="!nombre || !apellido || !rut || !email"
      >
        Guardar
      </v-btn>
    </div>

    <br />
    <h2>Empleados</h2>
    <div class="d-flex flex-wrap ga-3 mb-3">
      <div class="w-in">
        <v-text-field
          v-model="search"
          label="Buscar por rut, nombre, email o status"
          prepend-inner-icon="mdi-magnify"
          single-line
          variant="solo"
          hide-details
        ></v-text-field>
      </div>
      <div class="w-in">
        <v-combobox
          :items="lstOrigen"
          variant="solo"
          v-model="origenSel"
          hide-details
          pa-1
          label="Origen"
          ma-0
          @update:search="filtrarEmpleados"
          item-title="nombre"
          item-value="id"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.nombre }}</div>
          </template>
        </v-combobox>
      </div>
      <div class="w-in">
        <v-combobox
          :items="lstIntocable"
          variant="solo"
          label="Intocable"
          v-model="intocableSel"
          hide-details
          pa-1
          ma-0
          @update:search="filtrarEmpleados"
          item-title="nombre"
          item-value="id"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.nombre }}</div>
          </template>
        </v-combobox>
      </div>
    </div>

    <v-data-table :headers="headers" :items="lstEmpleados" :search="search">
      <template v-slot:item.intocable="{ item }">
        <v-checkbox
          v-model="item.intocable"
          hide-details
          pa-1
          ma-0
          disabled
          class="mt-2"
        ></v-checkbox>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-update" color="red" @click="showUpdateDialog(item)" />
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>
  </div>

  <v-dialog v-model="dialogDelete" persistent width="auto">
    <v-card>
      <v-card-title class="text-h5"> Borrar Empleado </v-card-title>
      <v-card-text> Esta seguro que desea borrar este empleado? </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogDelete = false">No</v-btn>
        <v-btn
          color="primary"
          text
          @click="deleteEmpleadoTable(empleadoToDelete)"
          >Si</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogUpdate" persistent width="1024">
    <v-card>
      <v-card-title
        style="text-align: center !important; margin-top: 15px !important"
      >
        <span class="text-h6">Actualizar Empleado</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                :items="lstStatus"
                variant="solo"
                v-model="emplStatusUpd"
                hide-details
                pa-1
                label="Status"
                ma-0
                item-title="nombre"
                item-value="id"
              >
                <template slot="item" slot-scope="data">
                  <div>{{ data.item.nombre }}</div>
                </template>
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="emplEmailUpd"
                variant="solo"
                hide-details
                pa-1
                ma-0
                label="Email"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-checkbox
                label="Intocable"
                v-model="emplIntUpd"
                variant="solo"
                hide-details
                pa-1
                ma-0
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col align-self="center">
              <v-sheet style="display: flex; justify-content: center">
                <v-btn
                  style="
                    background-color: #a3a3a3;
                    padding: 15px;
                    border-radius: 15px;
                    color: white;
                    display: flex;
                  "
                  variant="text"
                  @click="dialogUpdate = false"
                >
                  Cerrar
                </v-btn>
                <v-btn
                  :disabled="!emplStatusUpd || !emplEmailUpd"
                  style="
                    background-color: #207129;
                    padding: 15px;
                    border-radius: 15px;
                    color: white;
                    display: flex;
                  "
                  variant="text"
                  @click="updateEmpleado(empleadoToUpdate)"
                >
                  Actualizar
                </v-btn>
              </v-sheet>
            </v-col>
          </v-row></v-container
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../store/index.js";
import axios from "axios";
import { formatRut } from "rutlib";
import { validateRut } from "rutlib";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  name: "EmpleadoMantenedor",
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      nombre: null,
      apellido: null,
      rut: null,
      email: null,

      headers: [
        { title: "Rut", key: "rut", width: "100px" },
        { title: "Nombre", key: "fullName", align: "start", width: "150px" },
        { title: "Email", key: "email", align: "start", width: "100px" },
        { title: "Status", key: "status", align: "center", width: "50px" },
        {
          title: "Intocable",
          key: "intocable",
          align: "left",
          width: "50px",
        },
        { title: "", key: "actions", align: "center", width: "25px" },
      ],

      lstEmpleados: [],
      search: "",
      dialogDelete: false,
      dialogUpdate: false,

      empleadoToDelete: null,
      empleadoToUpdate: null,

      lstStatus: [
        { id: "activo", nombre: "activo" },
        { id: "inactivo", nombre: "inactivo" },
      ],
      emplStatusUpd: null,
      emplEmailUpd: null,
      emplIntUpd: false,

      intocable: false,

      lstOrigen: [
        { id: "Todos", nombre: "Todos" },
        { id: "Buk", nombre: "Buk" },
        { id: "No Buk", nombre: "No Buk" },
      ],

      lstIntocable: [
        { id: "Ambas", nombre: "Ambas" },
        { id: "Si", nombre: "Si" },
        { id: "No", nombre: "No" },
      ],
      origenSel: { id: "Todos", nombre: "Todos" },
      intocableSel: { id: "Ambas", nombre: "Ambas" },
      //origenSel: { id: "Buk", nombre: "Buk" },
    };
  },

  computed: {
    isValidEmail() {
      return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
    },
  },

  methods: {
    getEmpleados: async function () {
      try {
        this.isLoading = true;

        console.log(
          store.state.url +
            "api/Empleado/GetEmpleadosNoBuk?origen=" +
            this.origenSel.id +
            "&intocable=" +
            this.intocableSel.id
        );

        await axios
          .get(
            store.state.url +
              "api/Empleado/GetEmpleadosNoBuk?origen=" +
              this.origenSel.id +
              "&intocable=" +
              this.intocableSel.id,
            {
              headers: {},
            }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstEmpleados = response.data;
              //   console.log(JSON.stringify(this.lstEmpleados));
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    addEmpleado: async function () {
      try {
        if (this.nombre == null || this.nombre.trim() == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Debe ingresar un nombre",
            showConfirmButton: true,
          });
          return;
        }

        if (this.apellido == null || this.apellido.trim() == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Debe ingresar un apellido",
            showConfirmButton: true,
          });
          return;
        }

        if (this.rut == null || this.rut.trim() == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Debe ingresar un rut",
            showConfirmButton: true,
          });
          return;
        }

        if (this.email == null || this.email.trim() == "") {
          this.$swal.fire({
            icon: "warning",
            title: "Debe ingresar un email",
            showConfirmButton: true,
          });
          return;
        }

        if (!validateRut(this.rut)) {
          this.$swal.fire({
            icon: "warning",
            title: "Rut no valido",
            showConfirmButton: true,
          });
          return;
        }

        if (!this.isValidEmail) {
          this.$swal.fire({
            icon: "warning",
            title: "Email no valido",
            showConfirmButton: true,
          });
          return;
        }

        var empleado = {
          id: 0,
          personId: 0,
          firstName: this.nombre,
          surname: this.apellido,
          fullName: this.nombre + " " + this.apellido,
          email: this.email,
          personalEmail: this.email,
          rut: this.rut,
          status: "activo",
          buk: false,
          intocable: this.intocable,

          pictureUrl: "",
          secondSurname: "",
          currentJobRoleId: 0,
          currentJobBossId: 0,
          currentJobLocationId: 0,
          currentJobBossRut: "",
          currentJobRoleName: "",
        };

        await axios
          .post(store.state.url + "api/Empleado/PostEmpleado", empleado, {
            headers: {},
          })
          .then((response) => {
            if (response.status == 200) {
              this.lstEmpleados.unshift(empleado);
              console.log("Empleado agregado");

              this.$swal.fire({
                icon: "success",
                title: "Empleado Creado Exitosamente",
                showConfirmButton: false,
                timer: 2000,
              });

              this.nombre = null;
              this.apellido = null;
              this.rut = null;
              this.email = null;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    formatearRut: function () {
      var newRut = this.rut.replace(/[^0-9kK]+/g, "");
      this.rut = formatRut(newRut, true);
    },

    deleteEmpleado: async function (item) {
      try {
        await axios
          .delete(
            store.state.url + "api/Empleado/DeleteEmpleado?id=" + item.personId,
            {
              headers: {},
            }
          )
          .then((response) => {
            if (response.status == 200) {
              //this.getRoles();
              console.log("Empleado eliminado");
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    deleteEmpleadoTable: function (item) {
      this.deleteEmpleado(item).then(() => {
        const index = this.lstEmpleados.indexOf(item);
        this.lstEmpleados.splice(index, 1);

        this.dialogDelete = !this.dialogDelete;
      });
    },

    updateEmpleado: async function (item) {
      try {
        var empleado = item;

        empleado.status = this.emplStatusUpd.id;
        empleado.email = this.emplEmailUpd;
        empleado.intocable = this.emplIntUpd;

        empleado.pictureUrl = "";
        empleado.secondSurname = "";
        empleado.currentJobRoleId = 0;
        empleado.currentJobBossId = 0;
        empleado.currentJobLocationId = 0;
        empleado.currentJobBossRut = "";
        empleado.currentJobRoleName = "";

        await axios
          .put(store.state.url + "api/Empleado/UpdateEmpleado", empleado, {
            headers: {},
          })
          .then((response) => {
            if (response.status == 200) {
              console.log("Empleado actualizado");

              this.$swal.fire({
                icon: "success",
                title: "Empleado Actualizado Exitosamente",
                showConfirmButton: false,
                timer: 2000,
              });

              this.dialogUpdate = !this.dialogUpdate;

              window.location.reload();
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    showDeleteDialog: function (item) {
      this.empleadoToDelete = item;
      this.dialogDelete = !this.dialogDelete;
    },

    showUpdateDialog: function (item) {
      this.empleadoToUpdate = item;

      this.emplEmailUpd = this.lstEmpleados.find(
        (x) => x.email == item.email
      ).email;
      this.emplStatusUpd = this.lstStatus.find((x) => x.id == item.status);
      this.emplIntUpd = item.intocable;

      this.dialogUpdate = !this.dialogUpdate;
    },

    filtrarEmpleados: async function () {
      if (typeof this.origenSel != "string" && this.origenSel != null) {
        await this.getEmpleados();
      }
    },

    // checkIntocable: async function () {
    //   if (typeof this.origenSel != "string" && this.origenSel != null) {
    //     await this.getEmpleados();
    //   }
    // },
  },

  async created() {
    try {
      store.state.titulo = "Mantenedor Empleados";

      await this.getEmpleados();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

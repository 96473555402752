<style>
a:hover {
  background-color: transparent !important;
}

.v-list-item--density-default.v-list-item--one-line {
  min-height: 60px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 0.5px solid #ddd;
}
</style>
<template>
  <v-app>
    <v-app-bar color="#a61a2f" class="flex-grow-0" app dark>
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>

      <img src="./assets/blancoc.svg" width="100" height="50" />

      <div style="margin-left: 2rem">
        <h3>{{ $store.state.titulo }}</h3>
      </div>

      <v-app-bar-title></v-app-bar-title>

      <v-spacer></v-spacer>

      <label style="margin-right: 20px"> {{ $store.state.nombre }} </label>
      <div v-if="!$store.state.isLogged">
        <v-btn variant="text" icon="mdi-login" v-on:click="login()"></v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" color="#646363">
      <v-list>
        <v-list-item>
          <router-link
            to="/SolCompra"
            class="menu"
            style="color: white; font-size: 16px"
            >Crear Solicitud</router-link
          >
        </v-list-item>

        <v-list-item>
          <router-link
            to="/BuscaSolicitud"
            class="menu"
            style="color: white; font-size: 16px"
            >Buscar Solicitud</router-link
          >
        </v-list-item>

        <v-list-item v-if="esAdmin">
          <router-link
            to="/RolMantenedor"
            class="menu"
            style="color: white; font-size: 16px"
            >Mantenedor Roles</router-link
          >
        </v-list-item>

        <v-list-item v-if="esAdmin">
          <router-link
            to="/DeptoRolMantenedor"
            class="menu"
            style="color: white; font-size: 16px"
            >Mantenedor Departamento/Rol</router-link
          >
        </v-list-item>

        <v-list-item v-if="esAdmin">
          <router-link
            to="/EmpleadoMantenedor"
            class="menu"
            style="color: white; font-size: 16px"
            >Mantenedor Empleados</router-link
          >
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2" v-if="$store.state.isLogged">
          <v-btn
            v-on:click="logout()"
            style="
              background-color: #2b2b2b;
              padding: 15px;
              border-radius: 15px;
              color: white;
              margin-left: auto;
              margin-right: auto;
              display: flex;
            "
          >
            Cerrar sesion
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main fluid>
      <router-view></router-view>
    </v-main>
  </v-app>

  <loading v-model:active="isLoading" :is-full-page="fullPage" />
</template>

<script>
import { PublicClientApplication } from "@azure/msal-browser";
import AzureService from "@/services/AzureService";
import store from "./store/index";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  name: "App",
  components: {
    Loading,
  },
  data: () => ({
    drawer: false,
    isAuthenticated: false,
    esAdmin: false,
    isLoading: false,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },

  methods: {
    async login() {
      await this.$msalInstance
        .loginPopup({ scopes: ["User.Read", "Mail.Send"] })
        .then((response) => {
          //console.log(response);

          const myAcccount = this.$msalInstance.getAllAccounts();
          store.state.account = myAcccount[0];
          this.$emitter.emit("login", store.state.account);

          this.$cookies.set("isLogged", true);
          this.$cookies.set("token", response.accessToken);
          //console.log("token: " + response.accessToken);
          this.$cookies.set("account", store.state.account);
          this.$cookies.set(
            "username",
            store.state.account.username ??
              store.state.account.preferred_username
          );

          store.state.isLogged = true;
          store.state.nombre = store.state.account.name;

          /*           localStorage.detalleBusqueda = null;
          localStorage.fecha = "";
          localStorage.empresa = null;
          localStorage.departamento = null;
          localStorage.Estado = { id: "Todos", Nombre: "Todos" };
          localStorage.items = null;
          this.solId = "";
          this.solicitantePor = null;
          this.solicitante = null;
          this.tipoSol = { id: "Todos", Nombre: "Todos" }; */

          this.$router.push({ name: "BuscaSolicitud" });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async logout() {
      store.state.isLogged = false;
      store.state.nombre = "Invitado";
      store.state.email = "";

      /*       localStorage.detalleBusqueda = null;
      localStorage.fecha = "";
      localStorage.empresa = null;
      localStorage.departamento = null;
      localStorage.Estado = { id: "Todos", Nombre: "Todos" };
      localStorage.items = null;
      this.solId = "";
      this.solicitantePor = null;
      this.solicitante = null;
      this.tipoSol = { id: "Todos", Nombre: "Todos" }; */

      this.$cookies.remove("isLogged");
      this.$cookies.remove("token");
      this.$cookies.remove("account");

      await this.$msalInstance.logout();
      this.$emitter.emit("logout");
    },

    getRol: async function (email) {
      try {
        var tiene = [];

        this.isLoading = true;

        await axios
          .get(store.state.url + "api/Rol/GetRol?email=" + email, {
            headers: {},
          })
          .then((response) => {
            if (response.status == 200) {
              //this.tieneRol = response.data;
              //console.log(response.data);
              tiene = response.data;
            } else {
              //console.log(response.statusText);
              tiene = [];
            }
          })
          .catch(function (error) {
            console.log(error.message);
            tiene = [];
          });

        return tiene;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },

  async created() {
    var roles = await this.getRol(this.$cookies.get("username"));
    //var roles = await this.getRol("eherrera@oxxeanchile.cl");

    if (roles.some((r) => r.rolNombre === "Administrador")) {
      this.esAdmin = true;
    }

    const azureService = new AzureService();
    this.$msalInstance = new PublicClientApplication(
      azureService.getMsalConfig().value
    );

    try {
      await this.$msalInstance.initialize();
      await this.$msalInstance.handleRedirectPromise();
      this.$msalInstance.acquireTokenSilent(/* {
        scopes: ["user.read", "email", "mail.send" ],
      } */); //acquireTokenSilent();
    } catch (error) {
      console.log(error);
    }

    if (this.$cookies.isKey("isLogged")) {
      this.$store.state.isLogged = this.$cookies.get("isLogged");
    }

    if (this.$cookies.isKey("account")) {
      store.state.account = this.$cookies.get("account");
      store.state.nombre = store.state.account.name; //this.account.name;
    }
  },
};
</script>

import { createStore } from "vuex";

export default createStore({
    state: { //variables globales
        //url: "https://oxxean-solicitudcompra-prd.azurewebsites.net/",
        //url: "https://oxxean-solicitudcompra-dev.azurewebsites.net/",
        //url: "https://localhost:44312/",
        //url: "https://oxxean-solicitudcompra-test.azurewebsites.net/",
        url: import.meta.env.VITE_API_TARGET,

        isLogged: false,
        account: {},
        nombre: "Invitado",
        mail: "",
        titulo: "Solicitud de Compra",

        dimensionDepto: import.meta.env.VITE_DIMENSION_DEPTO, //"ee4705ba-9a58-ee11-8df1-00224835c63a",
        dimensionCeco: import.meta.env.VITE_DIMENSION_CECO, //"ed4705ba-9a58-ee11-8df1-00224835c63a",
        dimensionProyecto: import.meta.env.VITE_DIMENSION_PROYECTO, //"f04705ba-9a58-ee11-8df1-00224835c63a",

    },
    getters: {  // computed

    },
    mutations: { //setters sincronos

    },
    actions: { //setters asincronos

    },
    modelues: {

    }
});
<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2>Agregar Departamento Rol</h2>

    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label>Rol:</label>

        <v-combobox
          v-model="rolSel"
          :items="lstRoles"
          variant="solo"
          hide-details
          pa-1
          clearable
          ma-0
          item-title="nombre"
          item-value="id"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.nombre }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Empleado:</label>

        <v-combobox
          v-model="empleadoSel"
          :items="lstEmpleados"
          variant="solo"
          hide-details
          pa-1
          clearable
          ma-0
          item-title="rutDisplayName"
          item-value="rut"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.rutDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Departamento:</label>

        <v-combobox
          v-model="departamentoSel"
          :items="lstDepartamento"
          variant="solo"
          pa-1
          hide-details
          ma-0
          item-title="codeDisplayName"
          item-value="id"
          clearable
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <v-btn
        style="
          background-color: #a3a3a3;
          padding: 15px;
          border-radius: 15px;
          margin-top: 2.4em;
          color: white;
          display: flex;
        "
        dark
        @click="addDepartamentoEmpleados"
        :disabled="!empleadoSel || !rolSel || !departamentoSel"
      >
        Guardar
      </v-btn>
    </div>

    <br />
    <h2>Departamento Roles</h2>

    <v-text-field
      v-model="search"
      label="Buscar por departamento, empleado o rol"
      class="ma-2 w-50"
      density="compact"
      prepend-inner-icon="mdi-magnify"
      single-line
      variant="solo"
      hide-details
    ></v-text-field>

    <v-data-table :headers="headers" :items="lstTablaRoles" :search="search">
      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-update" color="red" @click="showUpdateDialog(item)" />
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>
  </div>

  <v-dialog v-model="dialogDelete" persistent width="auto">
    <v-card>
      <v-card-title class="text-h5"> Borrar Departamento Rol </v-card-title>
      <v-card-text>
        Esta seguro que desea borrar este Departamento rol?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogDelete = false">No</v-btn>
        <v-btn color="primary" text @click="deleteRolTable(rolToDelete)"
          >Si</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogUpdate" persistent width="1024">
    <v-card>
      <v-card-title>
        <span class="text-h5">Actualizar Departamento Rol</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                :items="lstRoles"
                v-model="rolSelUpd"
                variant="solo"
                hide-details
                pa-1
                clearable
                ma-0
                item-title="nombre"
                item-value="id"
              >
                <template slot="item" slot-scope="data">
                  <div>{{ data.item.nombre }}</div>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                :items="lstEmpleados"
                v-model="emplSelUpd"
                variant="solo"
                hide-details
                pa-1
                clearable
                ma-0
                item-title="rutDisplayName"
                item-value="rut"
              >
                <template slot="item" slot-scope="data">
                  <div>{{ data.item.rutDisplayName }}</div>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                :items="lstDepartamento"
                variant="solo"
                v-model="deptoSelUpd"
                pa-1
                hide-details
                ma-0
                item-title="codeDisplayName"
                item-value="id"
                clearable
              >
                <template slot="item" slot-scope="data">
                  <div>{{ data.item.codeDisplayName }}</div>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="dialogUpdate = false"
        >
          Cerrar
        </v-btn>
        <v-btn
          :disabled="!emplSelUpd || !rolSelUpd || !deptoSelUpd"
          color="blue-darken-1"
          variant="text"
          @click="updateRol(rolToUpdate)"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../store/index.js";
import axios from "axios";

export default {
  name: "DeptoRolMantenedor",
  components: {
    //Loading,
  },
  data() {
    return {
      lstEmpleados: [],
      empleadoSel: null,

      lstDepartamento: [],
      departamentoSel: null,

      lstRoles: [
        { id: "Aprobador", nombre: "Aprobador" },
        { id: "Autorizador", nombre: "Autorizador" },
      ],

      rolSel: null,

      lstTablaRoles: [],
      headers: [
        { title: "Departamento", key: "departamentoId", align: "start" },
        { title: "Empleado", key: "empleadoName", align: "start" },
        { title: "Rol", key: "rolEmail", align: "start" },
        { title: "", key: "actions", align: "start", width: "100px" },
      ],

      dialogDelete: false,
      dialogUpdate: false,

      rolToDelete: null,
      rolToUpdate: null,

      emplSelUpd: null,
      rolSelUpd: null,
      deptoSelUpd: null,
      search: "",
    };
  },
  methods: {
    getSolicitantes: async function () {
      try {
        await axios
          .get(store.state.url + "api/Empleado/GetEmpleadosMantenedor", {
            headers: {},
          })
          .then((response) => {
            if (response.status == 200) {
              this.lstEmpleados = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getDepartamentos: async function () {
      try {
        await axios
          .get(
            store.state.url + "api/Departamento/GetDepartamentosMantenedor",
            {
              headers: {},
            }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstDepartamento = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getDepartamentosEmpleados: async function () {
      try {
        await axios
          .get(
            store.state.url +
              "api/DepartamentoEmpleado/GetDepartamentosEmpleados",
            { headers: {} }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstTablaRoles = response.data;
              //console.log(this.lstTablaRoles);
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    addDepartamentoEmpleados: async function () {
      try {
        var depto = {
          departamentoId: this.departamentoSel.code,
          empleadoId: this.empleadoSel.email,
          rolEmail: this.rolSel.id,
        };

        await axios
          .post(
            store.state.url +
              "api/DepartamentoEmpleado/AddDepartamentoEmpleados",
            depto,
            {
              headers: {},
            }
          )
          .then((response) => {
            if (response.status == 200) {
              console.log("Rol Depto creado");

              this.$swal.fire({
                icon: "success",
                title: "Rol Departamento Creado Exitosamente",
                showConfirmButton: false,
                timer: 2000,
              });

              this.rolSel = null;
              this.empleadoSel = null;
              this.deptoSel = null;

              this.getDepartamentosEmpleados();
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    deleteRol: async function (item) {
      try {
        await axios
          .delete(
            store.state.url +
              "api/DepartamentoEmpleado/DeleteDepartamentoEmpleados?id=" +
              item.id,
            {
              headers: {},
            }
          )
          .then((response) => {
            if (response.status == 200) {
              //this.getRoles();
              console.log("Rol Depto eliminado");
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    deleteRolTable: function (item) {
      this.deleteRol(item).then(() => {
        const index = this.lstTablaRoles.indexOf(item);
        this.lstTablaRoles.splice(index, 1);

        this.dialogDelete = !this.dialogDelete;
      });
    },

    updateRol: async function (item) {
      try {
        var depto = {
          id: item.id,
          departamentoId: this.deptoSelUpd.code,
          empleadoId: this.emplSelUpd.email,
          rolEmail: this.rolSelUpd.id,
        };

        await axios
          .put(
            store.state.url +
              "api/DepartamentoEmpleado/UpdateDepartamentoEmpleados",
            depto,
            {
              headers: {},
            }
          )
          .then((response) => {
            if (response.status == 200) {
              console.log("Rol Depto actualizado");

              this.$swal.fire({
                icon: "success",
                title: "Rol Departamento Actualizado Exitosamente",
                showConfirmButton: false,
                timer: 2000,
              });

              this.getDepartamentosEmpleados();

              this.dialogUpdate = !this.dialogUpdate;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    showDeleteDialog: function (item) {
      this.rolToDelete = item;
      this.dialogDelete = !this.dialogDelete;
    },

    showUpdateDialog: function (item) {
      this.rolToUpdate = item;

      this.emplSelUpd = this.lstEmpleados.find(
        (x) => x.email == item.empleadoId
      );

      this.rolSelUpd = this.lstRoles.find((x) => x.id == item.rolEmail);

      this.deptoSelUpd = this.lstDepartamento.find(
        (x) => x.code == item.departamentoId
      );

      this.dialogUpdate = !this.dialogUpdate;
    },
  },

  async created() {
    try {
      store.state.titulo = "Mantenedor Departamento Roles";

      await this.getSolicitantes();
      await this.getDepartamentos();
      await this.getDepartamentosEmpleados();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style>
thead {
  background-color: #3d3d3d;
}

.v-data-table
  .v-table__wrapper
  > table
  > thead
  > tr
  > th.v-data-table__th--sortable:hover,
.v-data-table
  .v-table__wrapper
  > table
  tbody
  > tr
  > th.v-data-table__th--sortable:hover {
  cursor: unset;
}

.v-main {
  background-color: #dfdfdf;
}

.h2,
h2 {
  margin-bottom: 10px;
  font-weight: 600;
  border-bottom: 1px solid;
  font-size: 20px;
}
.v-table > .v-table__wrapper > table > thead > tr > th {
  padding: 0 7px !important;
}
</style>

<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2 style="margin-bottom: 15px; font-weight: 600; border-bottom: 1px solid">
      Filtros
    </h2>

    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label>N° Solicitud:</label>
        <v-text-field
          v-model="solId"
          type="number"
          pa-1
          hide-details
          ma-0
          variant="solo"
          clearable
          color="primary"
        />
      </div>

      <div class="w-in">
        <label>Ingresado Por:</label>

        <v-combobox
          v-model="solicitantePor"
          :items="lstSolicitantes"
          variant="solo"
          pa-1
          ma-0
          hide-details
          item-title="rutDisplayName"
          item-value="rut"
          clearable
          :disabled="!esAprobador && !esAutorizador"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.rutDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Solicitado Por:</label>

        <v-combobox
          v-model="solicitante"
          :items="lstSolicitantes"
          variant="solo"
          pa-1
          hide-details
          ma-0
          item-title="rutDisplayName"
          item-value="rut"
          clearable
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.rutDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Tipo Solicitud:</label>

        <v-combobox
          pa-1
          hide-details
          ma-0
          v-model="tipoSol"
          :items="lstTipoSolicitud"
          variant="solo"
          item-title="id"
          item-value="Nombre"
          @update:search="seleccionarRetiroBodega"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.Nombre }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Fecha Solicitud:</label>
        <v-text-field
          v-model="date"
          type="date"
          hide-details
          pa-1
          ma-0
          variant="solo"
          color="primary"
        />
      </div>

      <div class="w-in">
        <label>Empresa:</label>

        <v-combobox
          v-model="empresaSel"
          :items="lstEmpresas"
          variant="solo"
          pa-1
          hide-details
          ma-0
          item-title="displayName"
          item-value="id"
          clearable
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.displayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Departamento:</label>

        <v-combobox
          v-model="departamentoSel"
          :items="lstDepartamento"
          variant="solo"
          pa-1
          hide-details
          ma-0
          item-title="codeDisplayName"
          item-value="id"
          clearable
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Estado:</label>

        <v-combobox
          pa-1
          ma-0
          hide-details
          v-model="estadoSel"
          :items="lstEstados"
          variant="solo"
          item-title="id"
          item-value="Nombre"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.Nombre }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in" v-if="tipoSol.id == 'Retiro Bodega'">
        <label>Bodegas:</label>

        <v-combobox
          variant="solo"
          :items="lstBodegas"
          hide-details
          pa-1
          ma-0
          clearable
          item-title="codeDisplayName"
          item-value="id"
          v-model="BodegaSel"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div style="display: inline-flex" class="w-btn">
        <v-btn
          style="
            background-color: #a3a3a3;
            padding: 15px;
            border-radius: 15px;
            margin-top: 2.4em;
            color: white;
            margin-left: auto;
            display: flex;
          "
          density="default"
          @click="limpiarBusqueda()"
        >
          Limpiar
        </v-btn>
        <v-btn
          style="
            background-color: #595959;
            padding: 15px;
            border-radius: 15px;
            margin-top: 2.4em;
            color: white;
            margin-left: 10px;
            margin-right: auto;
            display: flex;
          "
          density="default"
          @click="buscarSolicitud()"
        >
          Buscar
        </v-btn>
      </div>
    </div>

    <div style="margin-top: 20px">
      <!--       <v-data-table
        v-model="selectedSol"
        :headers="headers"
        :items="lstDetalle"
        :show-select="isSelected"
      > -->

      <v-data-table
        v-model="selectedSol"
        :headers="headers"
        :items="lstDetalle"
      >
        <template v-slot:item.code="{ item }">
          {{ "SOC-" + item.code.toString().padStart(7, "0") }}
        </template>

        <template v-slot:item.fechaSolicitud="{ item }">
          {{ formatDate(item.fechaSolicitud) }}
        </template>

        <template v-slot:item.retBodega="{ item }">
          <v-checkbox
            variant="outlined"
            density="compact"
            hide-details
            v-model="item.retBodega"
          >
          </v-checkbox>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            icon="mdi-chevron-right"
            color="red"
            @click="navigate(item)"
          />
        </template>
      </v-data-table>

      <div v-if="isSelected" style="display: flex; margin-top: 10px">
        <v-btn
          v-if="esComprador"
          @click="enviarSolicitudes()"
          style="
            width: 200px;
            margin-top: 10px;
            background-color: #595959;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-left: auto;
            display: flex;
          "
        >
          Enviar a BC365
        </v-btn>
      </div>
    </div>

    <loading v-model:active="isLoading" :is-full-page="fullPage" />
  </div>
</template>

<script>
import store from "../store/index.js";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import moment from "moment";

export default {
  name: "app",
  components: {
    Loading,
  },
  data() {
    return {
      date: "", //(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      lstEmpresas: [],
      empresaSel: null,
      lstDepartamento: [],
      departamentoSel: null,

      lstDetalle: [],
      headers: [
        { title: "Id", key: "code", align: "start", width: "150px" },
        { title: "Tipo Solicitud", key: "tipoSolicitudId", align: "start" },
        { title: "Fecha Solicitud", key: "fechaSolicitud", align: "start" },
        {
          title: "Ingresado Por",
          key: "solicitantePorNombre",
          align: "start",
        },
        { title: "Empresa", key: "companyName" },
        { title: "Departamento", key: "departamentoName" },
        { title: "Solicitante", key: "solicitanteNombre", align: "start" },
        { title: "Estado", key: "estado", align: "start" },
        { title: "", key: "actions", align: "start" },
      ],
      selected: {},
      estadoSel: { id: "Todos", Nombre: "Todos" },
      //estadoSel: null,

      lstEstados: [
        { id: "Todos", Nombre: "Todos" },
        { id: "Aprobada", Nombre: "Aprobada" },
        { id: "Anulada", Nombre: "Anulada" },
        { id: "Autorizada", Nombre: "Autorizada" },
        { id: "Cerrada", Nombre: "Cerrada" },
        { id: "Creada", Nombre: "Creada" },
        { id: "Despachado", Nombre: "Despachado" },
        { id: "En Despacho", Nombre: "En Despacho" },
        { id: "Enviada", Nombre: "Enviada" },
        { id: "Guardada", Nombre: "Guardada" },
        { id: "Rechazada", Nombre: "Rechazada" },
      ],
      isLoading: false,
      fullPage: true,

      isSelected: false,
      selectedSol: [],

      solId: "",
      solicitantePor: null,
      solicitante: null,
      tipoSol: { id: "Todos", Nombre: "Todos" },

      lstSolicitantes: [],
      lstTipoSolicitud: [
        { id: "Todos", Nombre: "Todos" },
        { id: "Compra", Nombre: "Compra" },
        { id: "Retiro Bodega", Nombre: "Retiro Bodega" },
        { id: "Rendicion", Nombre: "Rendición" },
      ],
      rol: "",
      esComprador: false,
      esBodeguero: false,
      esAprobador: false,

      depto: {},
      lstBodegas: [],
      BodegaSel: null,
    };
  },

  methods: {
    navigate: function (item) {
      if (item.estado === "Guardada") {
        this.$router.push({
          name: "EditarSolicitud",
          params: { solicitudId: item.id },
        });
      } else {
        this.$router.push({
          name: "DetalleSolicitud",
          params: { solicitudId: item.id },
        });
      }
    },

    getCompanies: async function () {
      try {
        this.isLoading = true;

        await axios
          .get(store.state.url + "api/Company/GetCompanies", { headers: {} })
          .then((response) => {
            if (response.status == 200) {
              //console.log(JSON.stringify(response.data));
              this.lstEmpresas = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          })
          .finally(() => (this.isLoading = false));
      } catch (error) {
        console.log(error);
      }
    },

    getDepartamentosFiltro: async function () {
      try {
        await axios
          .get(store.state.url + "api/Departamento/GetDepartamentosFiltro", {
            headers: {},
          })
          .then((response) => {
            if (response.status == 200) {
              this.lstDepartamento = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getBodegas: async function () {
      try {
        this.isLoading = true;

        await axios
          .get(store.state.url + "api/Location/GetLocationsBuscador", {
            headers: {},
          })
          .then((response) => {
            if (response.status == 200) {
              this.lstBodegas = response.data;
              // console.log(JSON.stringify(this.lstBodegas));
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    buscarSolicitud: async function () {
      this.isLoading = true;

      try {
        var url =
          store.state.url +
          "api/SolicitudCompra/BuscarSolicitudCompra?estado=" +
          this.estadoSel.id;

        // console.log(url);

        if (this.date != "") {
          url = url + "&fecha=" + this.date;
        }

        if (this.empresaSel != null) {
          url = url + "&empresa=" + this.empresaSel.id;
        }

        if (this.departamentoSel != null) {
          url = url + "&departamento=" + this.departamentoSel.id;
        }

        if (this.solId != "") {
          url = url + "&solId=" + this.solId;
        }

        if (this.solicitantePor != null) {
          url = url + "&ingresado=" + this.solicitantePor.rut;
        }

        if (this.solicitante != null) {
          url = url + "&solicitante=" + this.solicitante.rut;
        }

        if (this.tipoSol != null) {
          url = url + "&tipoSol=" + this.tipoSol.id;
        }

        if (this.BodegaSel != null) {
          url = url + "&bodega=" + this.BodegaSel.id;
        }

        console.log(url);
        await axios
          .get(url, { headers: {} })
          .then(async (response) => {
            if (response.status == 200) {
              //this.lstDetalle = response.data;
              this.lstDetalle = await this.filtrarPorRol(response.data);

              if (this.estadoSel.id === "Aprobada") {
                this.isSelected = true;
              } else {
                this.isSelected = false;
              }

              var roles = await this.getRol(this.$cookies.get("username"));
              //var roles = await this.getRol("eherrera@oxxeanchile.cl");

              if (roles.some((r) => r.rolNombre === "Comprador")) {
                this.isSelected = true;

                this.esComprador = true;
                this.esBodeguero = false;
                this.esAprobador = false;
              }
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    enviarSolicitudes: function () {
      //console.log(JSON.stringify(this.selectedSol));

      if (this.selectedSol.length == 0) {
        this.$swal.fire({
          icon: "error",
          title: "Debe seleccionar al menos una solicitud",
          text: "Debe seleccionar al menos una solicitud",
        });
        return;
      }

      var errorMsg = [];
      var enviadas = [];
      this.isLoading = true;

      axios
        .post(
          store.state.url + "api/SolicitudCompra/EnviarPurchaseOrders",
          this.selectedSol
        )
        .then(async (response) => {
          if (response.status == 200) {
            for (var res of response.data) {
              if (res.message.includes("error")) {
                //console.log(JSON.stringify(res));
                var msg = JSON.parse(res.message); //JSON.parse(res.message);
                errorMsg.push(msg.error.message + "\n\n");
              } else {
                enviadas.push(res.solicitudId);
                //console.log(JSON.stringify(res));
              }
            }

            if (errorMsg.length > 0) {
              this.$swal.fire({
                icon: "error",
                title:
                  "Error al enviar " +
                  errorMsg.length +
                  " solicitudes a Business Central",
                text: errorMsg.join(),
              });
            } else {
              for (var item of enviadas) {
                this.cambiarEstado(item.id, "Enviada");
              }

              console.log("Solicitudes enviadas correctamente");

              this.$swal.fire({
                icon: "success",
                title: "Solicitudes enviadas correctamente",
                text:
                  "Se enviaron las" +
                  //response.data.length +
                  " solicitudes a Business Central",
              });
            }
          } else {
            console.log(response.statusText);
          }

          await this.buscarSolicitud();
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => (this.isLoading = false));
    },

    cambiarEstado: function (id, estado) {
      try {
        axios
          .put(
            store.state.url +
              "api/SolicitudCompra/UpdateEstado?id=" +
              id +
              "&estado=" +
              estado
          )
          .then((response) => {
            if (response.status == 200) {
              console.log("Cambio de estado realizado correctamente");
            }
          })
          .catch((error) => {
            this.errorMessage = error.message;
            console.error(error);
          });
      } catch (error) {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      }
    },

    getSolicitantes: async function () {
      try {
        await axios
          .get(store.state.url + "api/Empleado/GetEmpleados", { headers: {} })
          .then((response) => {
            if (response.status == 200) {
              this.lstSolicitantes = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getDeptosRol: async function (email, rol) {
      try {
        var deptos = [];

        await axios
          .get(
            store.state.url +
              "api/Rol/GeDeptostRol?email=" +
              email +
              "&rol=" +
              rol,
            {
              headers: {},
            }
          )
          .then((response) => {
            if (response.status == 200) {
              deptos = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });

        return deptos;
      } catch (error) {
        console.log(error);
      }
    },

    limpiarBusqueda: function () {
      this.date = "";
      this.empresaSel = null;
      this.departamentoSel = null;
      //this.estadoSel = { id: "Todos", Nombre: "Todos" };
      this.solId = "";
      //this.solicitantePor = null;
      this.solicitante = null;
      //this.tipoSol = { id: "Retiro Bodega", Nombre: "Retiro Bodega" };
      this.lstDetalle = [];

      /*       localStorage.detalleBusqueda = null;
      localStorage.fecha = "";
      localStorage.empresa = null;
      localStorage.departamento = null;
      localStorage.Estado = { id: "Todos", Nombre: "Todos" }; */
    },

    formatDate(value) {
      //return new Date(value).toISOString().substr(0, 10);
      return moment(value).format("DD-MM-yyyy");
    },

    setSelected: function () {
      if (this.estadoSel.id === "Aprobada") {
        return true;
      } else {
        return false;
      }
    },

    padleft: function (str) {
      return "SOC-" + this.padleft("0" + str, 7);
    },

    /*     selectCompany: async function () {
      if (typeof this.empresaSel != "string" && this.empresaSel != null) {
        //console.log(JSON.stringify(this.empresaSel));

        await this.getDepartamentos();
      }
    }, */

    getRol: async function (email) {
      try {
        var tiene = [];

        await axios
          .get(store.state.url + "api/Rol/GetRol?email=" + email, {
            headers: {},
          })
          .then((response) => {
            if (response.status == 200) {
              //this.tieneRol = response.data;
              //console.log(response.data);
              tiene = response.data;
            } else {
              console.log(response.statusText);
              tiene = [];
            }
          })
          .catch(function (error) {
            console.log(error.message);
            tiene = [];
          });

        return tiene;
      } catch (error) {
        console.log(error);
      }
    },

    getRolDepto: async function (email, depto) {
      try {
        var tiene = [];

        await axios
          .get(
            store.state.url +
              "api/Rol/GetRolDepto?email=" +
              email +
              "&depto=" +
              depto,
            {
              headers: {},
            }
          )
          .then((response) => {
            if (response.status == 200) {
              //this.tieneRol = response.data;
              //console.log(response.data);
              tiene = response.data;
            } else {
              console.log(response.statusText);
              tiene = null;
            }
          })
          .catch(function (error) {
            console.log(error.message);
            tiene = null;
          });

        return tiene;
      } catch (error) {
        console.log(error);
      }
    },

    obtenerEmpleado: async function (email) {
      try {
        this.isLoading = true;

        await axios
          .get(
            store.state.url + "api/Empleado/GetEmpleadoXEmail?email=" + email,
            { headers: {} }
          )
          .then(async (response) => {
            //var roles = await this.getRol(email);
            if (response.status == 200) {
              this.solicitantePor = this.lstSolicitantes.find(
                (x) => x.email == email
              );
              if (roles.some((r) => r.rolNombre === "Aprobador")) {
                this.esAprobador = true;
              }
              else
                this.esAprobador = false;

              if (roles.some((r) => r.rolNombre === "Autorizador")) {
                this.esAutorizador = true;
              }
              else
                this.esAutorizador = false;
              /*if (roles.some((r) => r.rolNombre === "Comprador")) {
                this.estadoSel = { id: "Aprobada", Nombre: "Aprobada" };

                this.isSelected = true;
                this.esComprador = true;
              }

              if (roles.some((r) => r.rolNombre === "Aprobador")) {
                this.esAprobador = true;

                this.estadoSel = { id: "Creada", Nombre: "Creada" };

                if (email.includes("@oxxeanchile.cl")) {
                  this.empresaSel = this.lstEmpresas.find((x) =>
                    x.displayName.includes("MARINA OXXEAN")
                  );
                } else {
                  this.empresaSel = this.lstEmpresas.find((x) =>
                    x.displayName.includes("TRABAJOS MARITIMOS")
                  );
                }

                //await this.getDepartamentoUsuario(email);
              }

              if (roles.some((r) => r.rolNombre === "Bodega")) {
                this.tipoSol = {
                  id: "Retiro Bodega",
                  Nombre: "Retiro Bodega",
                };

                this.estadoSel = { id: "Aprobada", Nombre: "Aprobada" };
              }

              if (roles.some((r) => r.rolNombre === "Contabilidad")) {
                this.tipoSol = {
                  id: "Rendicion",
                  Nombre: "Rendicion",
                };

                this.estadoSel = { id: "Autorizada", Nombre: "Autorizada" };
              }

              if (roles.some((r) => r.rolNombre === "Autorizador")) {
                this.tipoSol = { id: "Rendicion", Nombre: "Rendicion" };
                this.estadoSel = { id: "Creada", Nombre: "Creada" };

                if (email.includes("@oxxeanchile.cl")) {
                  this.empresaSel = this.lstEmpresas.find((x) =>
                    x.displayName.includes("TRABAJOS MARITIMOS")
                  );
                } else {
                  this.empresaSel = this.lstEmpresas.find((x) =>
                    x.displayName.includes("MARINA OXXEAN")
                  );
                }

                //await this.getDepartamentoUsuario(email);
              }

              if (
                roles.some(
                  (r) =>
                    r.rolNombre != "Comprador" &&
                    r.rolNombre != "Aprobador" &&
                    r.rolNombre != "Bodega" &&
                    r.rolNombre != "Autorizador" &&
                    r.rolNombre != "Contabilidad"
                )
              ) {
                //se carga ingresado por para usuarios no compradores,ni aprobadores, ni bodegueros
                this.solicitantePor = response.data;
                this.solPorId = this.solicitantePor.rut;
              }
            } else {
              this.solPorId = "";
              this.solPorNombre = "";

              if (roles.some((r) => r.rolNombre === "Aprobador")) {
                this.esAprobador = true;
                this.estadoSel = { id: "Creada", Nombre: "Creada" };

                if (email.includes("@oxxeanchile.cl")) {
                  this.empresaSel = this.lstEmpresas.find((x) =>
                    x.displayName.includes("MARINA OXXEAN")
                  );
                } else {
                  this.empresaSel = this.lstEmpresas.find((x) =>
                    x.displayName.includes("TRABAJOS MARITIMOS")
                  );
                }

                //await this.getDepartamentoUsuario(email);
              }

              if (roles.some((r) => r.rolNombre === "Comprador")) {
                this.estadoSel = { id: "Aprobada", Nombre: "Aprobada" };

                this.isSelected = true;
                this.esComprador = true;
              }

              if (roles.some((r) => r.rolNombre === "Autorizador")) {
                this.tipoSol = { id: "Rendicion", Nombre: "Rendicion" };
                this.estadoSel = { id: "Creada", Nombre: "Creada" };

                if (email.includes("@oxxeanchile.cl")) {
                  this.empresaSel = this.lstEmpresas.find((x) =>
                    x.displayName.includes("TRABAJOS MARITIMOS")
                  );
                } else {
                  this.empresaSel = this.lstEmpresas.find((x) =>
                    x.displayName.includes("MARINA OXXEAN")
                  );
                }

                //await this.getDepartamentoUsuario(email);
              }

              if (roles.some((r) => r.rolNombre === "Contabilidad")) {
                this.tipoSol = {
                  id: "Rendicion",
                  Nombre: "Rendicion",
                };

                this.estadoSel = { id: "Autorizada", Nombre: "Autorizada" };
              }

              if (roles.some((r) => r.rolNombre === "Bodega")) {
                this.tipoSol = {
                  id: "Retiro Bodega",
                  Nombre: "Retiro Bodega",
                };
                this.estadoSel = { id: "Aprobada", Nombre: "Aprobada" };
              }*/
            }

            await this.buscarSolicitud();

            this.isLoading = false;
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    filtrarPorRol: async function (ordenes) {
      try {
        var filtradas = [];
        var osPropias = [];

        var osAutorizador = [];
        var osContabilidad = [];
        var osAprobador = [];
        var osComprador = [];
        var osBodega = [];
        var osAdmin = [];

        var roles = await this.getRol(this.$cookies.get("username"));
        // var roles = await this.getRol("abuchholz@oxxeanchile.cl");

        if (this.solicitantePor != null) {
          osPropias = ordenes.filter(
            (s) => s.solicitantePorId === this.solicitantePor.rut
          );
        }

        // if (this.solicitantePor == null) {
        //   osPropias = ordenes;
        // }

        if (roles.some((r) => r.rolNombre === "Administrador")) {
          osAdmin = ordenes;
        }

        if (roles.some((r) => r.rolNombre === "Autorizador")) {
          // var deptosAutorizador = await this.getDeptosRol(
          //   "abuchholz@oxxeanchile.cl",
          //   "Autorizador"
          // );

          var deptosAutorizador = await this.getDeptosRol(
            this.$cookies.get("username"),
            "Autorizador"
          );

          var odsAut = ordenes.filter(
            (o) =>
              (o.estado === "Creada" ||
                o.estado === "Autorizada" ||
                o.estado === "Rechazada") &&
              //deptosAutorizador.includes(o.departamentoId) &&
              o.tipoSolicitudId === "Rendicion"
          );

          osAprobador = odsAut.filter((item) =>
            deptosAutorizador.includes(item.departamentoId)
          );
        }

        if (roles.some((r) => r.rolNombre === "Contabilidad")) {
          osContabilidad = ordenes.filter(
            (o) =>
              // (o.estado === "Autorizada" ||
              //   o.estado === "Rechazada" ||
              //   o.estado == "Enviada") &&
              o.tipoSolicitudId === "Rendicion"
          );
        }

        if (roles.some((r) => r.rolNombre === "Aprobador")) {
          // var deptosAprobador = await this.getDeptosRol(
          //   "abuchholz@oxxeanchile.cl",
          //   "Aprobador"
          // );

          var deptosAprobador = await this.getDeptosRol(
            this.$cookies.get("username"),
            "Aprobador"
          );

          var odsApro = ordenes.filter(
            (o) =>
              ((o.estado === "Creada" ||
                o.estado === "Aprobada" ||
                o.estado === "Rechazada") &&
                //deptosAprobador.includes(o.departamentoId) &&
                o.tipoSolicitudId === "Compra") ||
              o.tipoSolicitudId === "Retiro Bodega"
          );

          osAprobador = odsApro.filter((item) =>
            deptosAprobador.includes(item.departamentoId)
          );
        }

        if (roles.some((r) => r.rolNombre === "Comprador")) {
          osComprador = ordenes.filter(
            (o) =>
              // (o.estado === "Aprobada" || o.estado === "Rechazada") &&
              o.tipoSolicitudId === "Compra"
          );
        }

        if (roles.some((r) => r.rolNombre === "Bodega")) {
          osBodega = ordenes.filter(
            (o) =>
              // o.estado === "Aprobada" ||
              // o.estado === "Rechazada" ||
              // o.estado === "Despachado" ||
              // o.estado === "En Despacho" ||
              // o.estado === "Anulada") &&
              o.tipoSolicitudId === "Retiro Bodega"
          );
        }

        filtradas = [
          ...osPropias,
          ...osAutorizador,
          ...osContabilidad,
          ...osAprobador,
          ...osComprador,
          ...osBodega,
          ...osAdmin,
        ];

        //borrar repetidas
        filtradas = filtradas.filter(
          (v, i, a) => a.findIndex((t) => t.id === v.id) === i
        );

        if (this.estadoSel.id != "Todos") {
          filtradas = filtradas.filter((o) => o.estado === this.estadoSel.id);
        }

        if (this.tipoSol.id != "Todos") {
          filtradas = filtradas.filter(
            (o) => o.tipoSolicitudId === this.tipoSol.id
          );
        }

        filtradas.sort((a, b) => (a.code < b.code ? 1 : -1));

        return filtradas;
      } catch (error) {
        console.log(error);
      }
    },

    findCommonElements: function (arr1, arr2) {
      return arr1.some((item) => arr2.includes(item));
    },

    seleccionarRetiroBodega: async function () {
      if (this.tipoSol.id === "Retiro Bodega") {
        await this.getBodegas();
      }
    },
  },

  created: async function () {
    store.state.titulo = "Buscar Solicitud";

    this.loading = true;

    await this.getCompanies();
    await this.getDepartamentosFiltro();
    await this.getSolicitantes();

    this.loading = false;

    if (this.$cookies.get("username") != "") {
      await this.obtenerEmpleado(this.$cookies.get("username"));
      // await this.obtenerEmpleado("abuchholz@oxxeanchile.cl");
    }

    if (this.estadoSel.id === "Aprobada") {
      this.isSelected = true;
    } else {
      this.isSelected = false;
    }
  },
};
</script>

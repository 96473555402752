import { ref } from 'vue';

class AzureService {
    msalcConfig
    accessToken

    constructor() {
        this.msalcConfig = ref({
            auth: {
                //birdie
                /*                 clientId: "79a637b2-3afd-452d-ad8d-d5f8ebe2458e",
                                authority: "https://login.microsoftonline.com/8ada87e4-81f7-4d95-9d9f-bc086bdcd438", */

                clientId: "a6e92956-ea45-43fd-9b9b-ad0dc6435c8f",
                authority: "https://login.microsoftonline.com/34dbdd58-057c-4f60-a2ab-f648c44a555b",
            },
            cache: {
                cacheLocation: "localStorage"
            }
        });

        this.accessToken = ref("");
    }

    setAccessToken(token) {
        this.accessToken = token;
        return this.accessToken;
    }

    getAccessToken() {
        return this.accessToken;
    }

    getMsalConfig() {
        return this.msalcConfig;
    }
}

export default AzureService;
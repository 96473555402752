<style>
thead {
  background-color: #3d3d3d;
}

.v-data-table
  .v-table__wrapper
  > table
  > thead
  > tr
  > th.v-data-table__th--sortable:hover,
.v-data-table
  .v-table__wrapper
  > table
  tbody
  > tr
  > th.v-data-table__th--sortable:hover {
  cursor: unset;
  color: white;
}

.v-table > .v-table__wrapper > table > tbody > tr > th,
.v-table > .v-table__wrapper > table > thead > tr > th,
.v-table > .v-table__wrapper > table > tfoot > tr > th {
  font-weight: 500;
  user-select: none;
  text-align: start;
  color: white;
}

textarea {
  height: 80px;
}
</style>

<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2 style="margin-bottom: 15px; font-weight: 600; border-bottom: 1px solid">
      Datos Generales
    </h2>
    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label><b></b>Tipo Solicitud:</label>

        <v-combobox
          pa-1
          hide-details
          ma-0
          v-model="tipoSol"
          :items="lstTipoSolicitud"
          variant="solo"
          item-title="id"
          item-value="Nombre"
          @update:search="cambioRetBodega"
        >
          <template slot="item" slot-scope="data">
            <div>
              <p class="font-weight-bold">{{ data.item.Nombre }}</p>
            </div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Fecha Solicitud:</label>

        <v-text-field
          pa-1
          hide-details
          ma-0
          v-model="date"
          type="date"
          variant="solo"
          color="primary"
          disabled
          required
        />
      </div>

      <div class="w-in">
        <label>Ingresado Por:</label>

        <v-text-field
          variant="solo"
          pa-1
          ma-0
          v-model="solPorId"
          readonly
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label> Rut Ingresado Por: </label>
        <v-text-field
          variant="solo"
          pa-1
          ma-0
          v-model="solPorNombre"
          readonly
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Empresa:</label>

        <v-combobox
          v-model="empresaSel"
          pa-1
          ma-0
          hide-details
          :items="lstEmpresas"
          variant="solo"
          item-title="displayName"
          item-value="id"
          @update:search="selectCompany"
          clearable
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.displayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Departamento:</label>

        <v-combobox
          v-model="departamentoSel"
          pa-1
          ma-0
          hide-details
          :items="lstDepartamento"
          variant="solo"
          item-title="codeDisplayName"
          item-value="id"
          clearable
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Solicitante:</label>

        <v-combobox
          v-model="solicitanteSel"
          :items="lstSolicitantes"
          variant="solo"
          pa-1
          hide-details
          ma-0
          item-title="rutDisplayName"
          item-value="personId"
          clearable
          return-object
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.rutDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <!-- <div class="w-in" v-if="tipoSol.id != 'Rendicion'">
        <label>Proyectos:</label>

        <v-combobox
          v-model="proyectoSel"
          pa-1
          ma-0
          hide-details
          :items="lstProyectos"
          variant="solo"
          item-title="codeDisplayName"
          item-value="id"
          clearable
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </div> -->

      <div class="w-in" v-if="tipoSol.id == 'Compra'">
        <label>Proveedor:</label>

        <v-combobox
          v-model="vendorSel"
          :items="lstVendors"
          variant="solo"
          item-title="numberDisplayName"
          item-value="id"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.numberDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in" v-if="tipoSol.id == 'Retiro Bodega'">
        <label>Cod. Bod:</label>

        <v-combobox
          variant="solo"
          :items="lstBodegas"
          hide-details
          pa-1
          ma-0
          clearable
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          v-model="codigoBodega"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Observación:</label>

        <v-textarea variant="solo" pa-1 ma-0 v-model="obs" hide-details>
        </v-textarea>
      </div>
    </div>
  </div>

  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2 style="font-weight: 600">Detalle Solicitud</h2>

    <div class="w-in">
      <!-- <label style="font-size:16px;">Busca Detalle:</label> -->

      <v-combobox
        style="font-size: 16px; margin-bottom: 10px"
        pa-1
        ref="mySearch"
        :loading="loading"
        :items="lstItems"
        :hide-no-data="false"
        v-model="item"
        item-title="numberDisplayName"
        item-value="id"
        variant="solo"
        dense
        hide-details
        clearable
        label="Busca un Material/Servicio"
        no-data-text="No se encontro Material/Servicio"
        @update:search="BuscarMaterial"
        return-object
      >
        <template slot="item" slot-scope="data">
          <div>{{ data.item.numberDisplayName }}</div>
        </template>
      </v-combobox>
    </div>

    <v-data-table
      v-if="tipoSol != null && tipoSol.id == 'Compra'"
      :headers="headers"
      :items="lstDetalle"
    >
      <template v-slot:item.Cantidad="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          v-model="item.Cantidad"
          @update:modelValue="CalcularTotal(item)"
        >
        </v-text-field>
      </template>

      <template v-slot:item.Precio="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          v-model="item.Precio"
          @update:modelValue="CalcularTotal(item)"
        >
        </v-text-field>
      </template>

      <template v-slot:item.Total="{ item }">
        {{ formatMoney(item.Total) }}
      </template>

      <template v-slot:item.CecoId="{ item }">
        <v-combobox
          variant="outlined"
          :items="lstCeco"
          density="compact"
          pa-1
          ma-0
          hide-details
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          v-model="item.CecoId"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </template>

      <template v-slot:item.ProyectoId="{ item }">
        <v-combobox
          v-model="item.ProyectoId"
          pa-1
          ma-0
          hide-details
          :items="lstProyectos"
          variant="outlined"
          density="compact"
          item-title="codeDisplayName"
          item-value="id"
          clearable
          :return-object="false"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </template>

      <template v-slot:item.OTMantencion="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          v-model="item.OTMantencion"
        >
        </v-text-field>
      </template>

      <template v-slot:item.CodigoBodega="{ item }">
        <v-combobox
          variant="outlined"
          :items="lstBodegas"
          density="compact"
          hide-details
          pa-1
          ma-0
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          v-model="item.CodigoBodega"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>

    <v-data-table
      v-if="tipoSol != null && tipoSol.id == 'Retiro Bodega'"
      :headers="headersRetiro"
      :items="lstDetalle"
    >
      <template v-slot:item.Tipo="{ item }">
        {{ item.Tipo }}
      </template>

      <template v-slot:item.Cantidad="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          v-model="item.Cantidad"
          @update:modelValue="CalcularTotal(item)"
        >
        </v-text-field>
      </template>

      <template v-slot:item.CecoId="{ item }">
        <v-combobox
          variant="outlined"
          :items="lstCeco"
          density="compact"
          pa-1
          ma-0
          hide-details
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          clearable
          v-model="item.CecoId"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </template>

      <template v-slot:item.ProyectoId="{ item }">
        <v-combobox
          v-model="item.ProyectoId"
          pa-1
          ma-0
          hide-details
          :items="lstProyectos"
          variant="outlined"
          density="compact"
          item-title="codeDisplayName"
          item-value="id"
          clearable
          :return-object="false"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-combobox>
      </template>

      <template v-slot:item.OTMantencion="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          v-model="item.OTMantencion"
        >
        </v-text-field>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>

    <v-data-table
      v-if="tipoSol.id === 'Rendicion'"
      :headers="headersRendicion"
      :items="lstDetalle"
    >
      <template v-slot:item.TipoDoc="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstTipoDoc"
          density="compact"
          pa-1
          ma-0
          hide-details
          item-title="Nombre"
          item-value="id"
          :return-object="false"
          v-model="item.TipoDoc"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.Nombre }}</div>
          </template>
        </v-autocomplete>
      </template>

      <template v-slot:item.NroDoc="{ item }">
        <div
          v-if="item.TipoDoc == 'Sin Documento' /* || item.TipoDoc == 'Vale' */"
        >
          <v-text-field
            pa-1
            ma-0
            v-model="item.NroDoc"
            variant="outlined"
            color="primary"
            required
            density="compact"
            hide-details
            readonly
          />
        </div>

        <div v-else>
          <v-text-field
            pa-1
            ma-0
            v-model="item.NroDoc"
            variant="outlined"
            color="primary"
            required
            density="compact"
            hide-details
          />
        </div>
      </template>

      <template v-slot:item.FechaDoc="{ item }">
        <v-text-field
          pa-1
          ma-0
          v-model="item.FechaDoc"
          type="date"
          variant="outlined"
          color="primary"
          required
          density="compact"
          hide-details
        />
      </template>

      <template v-slot:item.ProveedorId="{ item }">
        <v-combobox
          variant="outlined"
          :items="lstVendors"
          density="compact"
          pa-1
          ma-0
          hide-details
          item-title="numberDisplayName"
          item-value="id"
          :return-object="false"
          v-model="item.ProveedorId"
          clearable
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.displayName }}</div>
          </template>
        </v-combobox>
      </template>

      <template v-slot:item.DescGasto="{ item }">
        <v-text-field
          pa-1
          ma-0
          v-model="item.DescGasto"
          variant="outlined"
          color="primary"
          required
          density="compact"
          hide-details
        />
      </template>

      <template v-slot:item.Monto="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          v-model="item.Monto"
          @update:modelValue="RendicionTotal(item)"
        >
        </v-text-field>
      </template>

      <template v-slot:item.CecoId="{ item }">
        <v-autocomplete
          variant="outlined"
          :items="lstCeco"
          density="compact"
          pa-1
          ma-0
          hide-details
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          v-model="item.CecoId"
          clearable
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-autocomplete>
      </template>

      <template v-slot:item.ProyectoId="{ item }">
        <v-autocomplete
          v-model="item.ProyectoId"
          pa-1
          ma-0
          :items="lstProyectos"
          variant="outlined"
          item-title="codeDisplayName"
          item-value="id"
          density="compact"
          clearable
          :return-object="false"
          hide-details
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.codeDisplayName }}</div>
          </template>
        </v-autocomplete>
      </template>

      <template v-slot:item.OTMantencion="{ item }">
        <v-text-field
          variant="outlined"
          density="compact"
          hide-details
          pa-1
          ma-0
          v-model="item.OTMantencion"
        >
        </v-text-field>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>

    <div
      v-if="tipoSol.id == 'Rendicion'"
      style="
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
        display: flex;
        justify-content: flex-end;
      "
    >
      Total: {{ formatMoney(total) }}
    </div>

    <div class="d-flex flex-wrap">
      <div class="w-in">
        <v-file-input
          pa-1
          style="margin-bottom: 5px"
          ma-0
          hide-details
          v-model="selectedFiles"
          multiple
          label="Selecciona archivos"
          variant="solo"
          @update:modelValue="addFiles"
        >
        </v-file-input>

        <v-chip closable v-for="f in file">
          {{ f.name }}
        </v-chip>
      </div>

      <div class="w-69 d-flex mt-3" style="justify-content: end">
        <v-btn
          style="
            background-color: #595959;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-right: 5px;
            display: flex;
          "
          density="default"
          v-on:click="guardarSolicitud()"
          >Guardar</v-btn
        >

        <v-btn
          style="
            background-color: #10510e;
            padding: 15px;
            border-radius: 15px;
            color: white;
            display: flex;
          "
          density="default"
          v-on:click="crearSolicitud()"
          >Crear Solicitud</v-btn
        >
      </div>
    </div>
  </div>

  <v-dialog v-model="dialogDelete" persistent width="auto">
    <v-card>
      <v-card-title class="text-h5"> Borrar Item </v-card-title>
      <v-card-text>
        Esta seguro que desea item el material `{{
          itemToDelete.CodigoMaterial
        }}` ?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogDelete = false">No</v-btn>
        <v-btn color="primary" text @click="deleteMaterial(itemToDelete)"
          >Si</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <loading v-model:active="isLoading" :is-full-page="fullPage" />
</template>

<script>
import store from "../store/index.js";
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { PublicClientApplication } from "@azure/msal-browser";
import AzureService from "@/services/AzureService";
//import { ref } from "vue";

export default {
  name: "app",
  components: {
    Loading,
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      solPorId: "",
      solPorNombre: "",

      solicitantePor: null,

      lstEmpresas: [],
      empresaSel: null,
      lstDepartamento: [],
      departamentoSel: null,

      lstSolicitantes: [],
      solicitanteSel: null,

      solId: "",
      solNombre: "",
      obs: "",

      lstDetalle: [],
      headers: [
        { title: "Id", key: "id", width: "25px" },
        { title: "Tipo", key: "Tipo", align: "start", width: "50px" },
        {
          title: "Cod",
          key: "CodigoMaterial",
          align: "center",
          width: "120px",
        },
        {
          title: "Material/Servicio",
          key: "Material",
          align: "start",
          width: "100px",
        },
        { title: "UN", key: "Unidad", align: "center" },
        { title: "Q", key: "Cantidad", align: "center", width: "100px" },
        { title: "Precio", key: "Precio", align: "center", width: "150px" },
        { title: "Total", key: "Total", align: "center" },
        { title: "Ceco", key: "CecoId", align: "start", width: "250px" },
        {
          title: "Proyecto",
          key: "ProyectoId",
          align: "center",
          width: "200px",
        },
        {
          title: "Bodega",
          key: "CodigoBodega",
          align: "center",
          width: "200px",
        },
        {
          title: "OT Mantención",
          key: "OTMantencion",
          align: "center",
          width: "50px",
        },

        { title: "", key: "actions", align: "center", width: "25px" },
      ],

      headersRendicion: [
        { title: "Id", key: "id", width: "25px" },
        {
          title: "Tipo Docto",
          key: "TipoDoc",
          align: "center",
          width: "220px",
        },
        { title: "N° Docto", key: "NroDoc", align: "center", width: "100px" },
        {
          title: "Fecha Docto",
          key: "FechaDoc",
          align: "center",
          width: "200px",
        },
        {
          title: "Proveedor",
          key: "ProveedorId",
          align: "center",
          width: "200px",
        },
        {
          title: "Material/Servicio",
          key: "Material",
          align: "center",
          width: "150px",
        },
        {
          title: "Descripción del Gasto",
          key: "DescGasto",
          align: "center",
          width: "150px",
        },
        { title: "Ceco", key: "CecoId", align: "center", width: "250px" },
        //{ title: "Depto", key: "DeptoId", align: "center", width: "250px" },
        {
          title: "Proyecto",
          key: "ProyectoId",
          align: "center",
          width: "200px",
        },
        { title: "Monto", key: "Monto", align: "center", width: "150px" },
        {
          title: "OT Mantención",
          key: "OTMantencion",
          align: "center",
          width: "50px",
        },
        { title: "", key: "actions", align: "center", width: "25px" },
      ],

      headersRetiro: [
        { title: "Id", key: "id", width: "25px" },
        { title: "Tipo", key: "Tipo", align: "start", width: "100px" },
        {
          title: "Cod",
          key: "CodigoMaterial",
          align: "center",
          width: "100px",
        },
        {
          title: "Material/Servicio",
          key: "Material",
          align: "start",
          width: "200px",
        },
        { title: "UN", key: "Unidad", align: "center", width: "25px" },
        { title: "Q", key: "Cantidad", align: "center", width: "100px" },
        /*         { title: "Precio", key: "Precio", align: "center", width: "150px" },
        { title: "Total", key: "Total", align: "center" }, */
        { title: "Ceco", key: "CecoId", align: "start", width: "250px" },
        {
          title: "Proyecto",
          key: "ProyectoId",
          align: "center",
          width: "200px",
        },
        {
          title: "OT Mantención",
          key: "OTMantencion",
          align: "center",
          width: "50px",
        },
        { title: "", key: "actions", align: "center", width: "25px" },
      ],

      lstCeco: [],
      cecoSel: null,

      loading: false,
      lstItems: [],
      item: null,
      guid: "",
      selectedFiles: [],
      file: [],
      filesUploaded: [],
      fileName: "",

      lstBodegas: [],
      bodegaSel: null,
      detContador: 1,
      dialogDelete: false,

      isLoading: false,
      fullPage: true,
      account: {},

      lstVendors: [],
      vendorSel: null,
      lstTipoSolicitud: [
        { id: "Compra", Nombre: "Compra" },
        { id: "Rendicion", Nombre: "Rendición" },
        { id: "Retiro Bodega", Nombre: "Retiro Bodega" },
      ],

      lstProyectos: [],
      proyectoSel: null,
      tipoSol: { id: "Compra", Nombre: "Compra" },
      //tipoSol: { id: "Rendicion", Nombre: "Rendicion" },

      lstTipoDoc: [
        { id: "Sin Documento", Nombre: "Sin Documento" },
        { id: "Boleta", Nombre: "Boleta" },
        { id: "Factura", Nombre: "Factura" },
        { id: "Vale", Nombre: "Vale" },
        { id: "B. Honorarios", Nombre: "B. Honorarios" },
      ],

      total: 0,
      codigoBodega: null,
    };
  },
  methods: {
    BuscarMaterial: async function () {
      if (this.empresaSel == null) {
        this.$swal.fire({
          icon: "warning",
          title: "Debe seleccionar una empresa",
          showConfirmButton: true,
        });

        this.item = null;
        return;
      }

      if (typeof this.item === "string" || this.item instanceof String) {
        this.loading = true;
        if (this.item != "" && this.item.length > 2) {
          axios
            .get(
              store.state.url +
                "api/Item/BuscadorItem?item=" +
                this.item +
                "&companyId=" +
                this.empresaSel.id,
              {
                headers: {},
              }
            )
            .then((response) => {
              if (response.status == 200) {
                this.lstItems = response.data;
                this.loading = false;
              } else {
                console.log(response.statusText);
              }
            })
            .catch(function (error) {
              console.log(error.message);
            })
            .finally(() => (this.loading = false));
        }
      } else {
        if (this.item != null) {
          //localStorage.items = JSON.stringify(this.lstItems);
          let det = {};

          if (this.tipoSol.id == "Rendicion") {
            det = {
              idItem: this.item.id,
              id: this.detContador,
              EncabezadoId: this.guid,
              CodigoMaterial: this.item.number,
              Material: this.item.displayName,
              RetiroBodega: false,
              Monto: 0,
              OTMantencion: null,
              Tipo: this.item.itemCategoryCode,
            };
          } else {
            if (this.tipoSol.id == "Retiro Bodega") {
              det = {
                idItem: this.item.id,
                id: this.detContador,
                Tipo: this.item.itemCategoryCode,
                EncabezadoId: this.guid,
                CodigoMaterial: this.item.number,
                Material: this.item.displayName,
                Unidad: "UN",
                Cantidad: 0,
                Precio: 0,
                Total: 0,
                RetiroBodega: true,
                OTMantencion: null,
              };
            }

            if (this.tipoSol.id == "Compra") {
              det = {
                idItem: this.item.id,
                id: this.detContador,
                Tipo: this.item.itemCategoryCode,
                EncabezadoId: this.guid,
                CodigoMaterial: this.item.number,
                Material: this.item.displayName,
                Unidad: "UN",
                Cantidad: 0,
                Precio: 0,
                Total: 0,
                OTMantencion: null,
                //RetiroBodega: true,
              };
            }

            // if (this.tipoSol.id == "Retiro Bodega") {
            //   det.RetiroBodega = true;
            // } else {
            //   det.RetiroBodega = false;
            // }

            if (this.item.type == "Service") {
              det.Cantidad = 1;
            }
          }

          //console.log(det);
          this.lstDetalle.push(det);

          this.item = null;
          this.detContador++;
        }

        this.lstItems = [];
      }
    },

    seleccionarSolicitante: function () {
      if (this.solicitanteSel != null) {
        this.solId = this.solicitanteSel.rut;
        this.solNombre = this.solicitanteSel.fullName;
      } else {
        this.solId = "";
        this.solNombre = "";
      }
    },

    getVendors: async function () {
      try {
        await axios
          .get(
            store.state.url +
              "api/Vendors/GetVendors?company=" +
              this.empresaSel.id,
            { headers: {} }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstVendors = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getCompanies: async function () {
      try {
        this.isLoading = true;

        await axios
          .get(store.state.url + "api/Company/GetCompanies", { headers: {} })
          .then((response) => {
            if (response.status == 200) {
              this.lstEmpresas = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getDepartamentos: async function () {
      try {
        await axios
          .get(
            store.state.url +
              "api/Departamento/GetDepartamentos?company=" +
              this.empresaSel.id,
            {
              headers: {},
            }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstDepartamento = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getCeco: async function () {
      try {
        await axios
          .get(
            store.state.url + "api/Ceco/GetCecos?company=" + this.empresaSel.id,
            { headers: {} }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstCeco = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getBodegas: async function () {
      try {
        await axios
          .get(
            store.state.url +
              "api/Location/GetLocations?company=" +
              this.empresaSel.id,
            { headers: {} }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstBodegas = response.data;
              // console.log(JSON.stringify(this.lstBodegas));
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getProyectos: async function () {
      try {
        await axios
          .get(
            store.state.url +
              "api/Proyecto/GetProyectos?company=" +
              this.empresaSel.id,
            { headers: {} }
          )
          .then((response) => {
            if (response.status == 200) {
              this.lstProyectos = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getSolicitantes: async function () {
      try {
        await axios
          .get(store.state.url + "api/Empleado/GetEmpleados", { headers: {} })
          .then((response) => {
            if (response.status == 200) {
              this.lstSolicitantes = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    crearSolicitud: async function () {
      try {
        if (this.tipoSol == null) {
          this.$swal.fire({
            icon: "warning",
            title: "Debe seleccionar un tipo de solicitud",
            showConfirmButton: true,
          });
          return;
        }

        if (
          this.solPorId == null ||
          this.solPorId == "" ||
          this.solPorNombre == null ||
          this.solPorNombre == ""
        ) {
          this.$swal.fire({
            icon: "warning",
            title: "Debe haber un ingresado por",
            showConfirmButton: true,
          });
          return;
        }

        if (this.empresaSel == null) {
          this.$swal.fire({
            icon: "warning",
            title: "Debe seleccionar una empresa",
            showConfirmButton: true,
          });
          return;
        }

        if (this.departamentoSel == null) {
          this.$swal.fire({
            icon: "warning",
            title: "Debe seleccionar un departamento",
            showConfirmButton: true,
          });
          return;
        }

        if (this.solicitanteSel == null) {
          this.$swal.fire({
            icon: "warning",
            title: "Debe seleccionar un Solicitante",
            showConfirmButton: true,
          });
          return;
        }

        if (this.lstDetalle.length == 0) {
          this.$swal.fire({
            icon: "warning",
            title: "Debe agregar al menos un material",
            showConfirmButton: true,
          });
          return;
        }

        if (this.lstDetalle.some((x) => x.CecoId == null)) {
          this.$swal.fire({
            icon: "warning",
            title: "Debe seleccionar un ceco",
            showConfirmButton: true,
          });
          return;
        }

        var encab = {};
        var detalle = [];

        this.isLoading = true;

        if (this.tipoSol.id == "Compra" || this.tipoSol.id == "Retiro Bodega") {
          if (this.lstDetalle.some((x) => x.Cantidad == 0)) {
            this.$swal.fire({
              icon: "warning",
              title: "Debe ingresar una cantidad mayor a 0",
              showConfirmButton: true,
            });
            return;
          }

          encab = {
            Id: this.guid,
            FechaSolicitud: this.date,
            SolicitantePorId: this.solPorId,
            SolicitantePorNombre: this.solPorNombre,
            CompanyId: this.empresaSel.id,
            DepartamentoId: this.departamentoSel.id,
            Observacion: this.obs,
            SolicitanteId: this.solicitanteSel.rut,
            SolicitanteNombre: this.solicitanteSel.fullName,
            //VendorId: this.vendorSel != null ? this.vendorSel.id : null,
            Estado: "Creada",
            TipoSolicitudId: this.tipoSol.id,
            ProyectoId: this.proyectoSel != null ? this.proyectoSel.id : null,
            //CodigoBodega: this.codigoBodega != null ? this.codigoBodega : null,
          };

          detalle = this.lstDetalle;

          if (this.tipoSol.id == "Compra") {
            if (this.vendorSel == null) {
              this.$swal.fire({
                icon: "warning",
                title: "Debe seleccionar un proveedor",
                showConfirmButton: true,
              });
              return;
            }

            encab.VendorId = this.vendorSel != null ? this.vendorSel.id : null;
            encab.VendorNumber =
              this.vendorSel != null ? this.vendorSel.number : null;
            encab.PayToVendorId =
              this.vendorSel != null ? this.vendorSel.id : null;
            encab.PayToVendorNumber =
              this.vendorSel != null ? this.vendorSel.number : null;

            for (let i = 0; i < detalle.length; i++) {
              if (
                detalle[i].CodigoMaterial.includes("MAT-") &&
                detalle[i].CodigoBodega == null
              ) {
                this.$swal.fire({
                  icon: "warning",
                  title:
                    "Se debe seleccionar bodega para el item " +
                    detalle[i].Material,
                  showConfirmButton: true,
                });
                return;
              }

              if (
                this.$route.params.solicitudId != undefined ||
                this.$route.params.solicitudId != null
              ) {
                // detalle[i].CecoId = detalle[i].CecoId.id;
                // detalle[i].ProyectoId = detalle[i].ProyectoId.id;
                // detalle[i].CodigoBodega =
                //   detalle[i].CodigoBodega != null
                //     ? detalle[i].CodigoBodega.id
                //     : null;

                if (detalle[i].CecoId != null) {
                  if (
                    typeof detalle[i].CecoId === "string" ||
                    detalle[i].CecoId instanceof String
                  ) {
                    detalle[i].CecoId = detalle[i].CecoId;
                  } else {
                    detalle[i].CecoId = detalle[i].CecoId.id;
                  }
                }

                if (detalle[i].ProyectoId != null) {
                  if (
                    typeof detalle[i].ProyectoId === "string" ||
                    detalle[i].ProyectoId instanceof String
                  ) {
                    detalle[i].ProyectoId = detalle[i].ProyectoId;
                  } else {
                    detalle[i].ProyectoId = detalle[i].ProyectoId.id;
                  }
                }

                if (detalle[i].CodigoBodega != null) {
                  if (
                    typeof detalle[i].CodigoBodega === "string" ||
                    detalle[i].CodigoBodega instanceof String
                  ) {
                    detalle[i].CodigoBodega = detalle[i].CodigoBodega;
                  } else {
                    detalle[i].CodigoBodega = detalle[i].CodigoBodega.id;
                  }
                }
              }
            }
          }

          if (this.tipoSol.id == "Retiro Bodega") {
            if (this.codigoBodega == null) {
              this.$swal.fire({
                icon: "warning",
                title: "Debe seleccionar una bodega",
                showConfirmButton: true,
              });
              return;
            }

            encab.CodigoBodega =
              this.codigoBodega != null ? this.codigoBodega : null;

            if (
              this.$route.params.solicitudId != undefined ||
              this.$route.params.solicitudId != null
            ) {
              encab.CodigoBodega =
                this.codigoBodega != null ? this.codigoBodega.id : null;

              for (let i = 0; i < detalle.length; i++) {
                if (detalle[i].CecoId != null) {
                  if (
                    typeof detalle[i].CecoId === "string" ||
                    detalle[i].CecoId instanceof String
                  ) {
                    detalle[i].CecoId = detalle[i].CecoId;
                  } else {
                    detalle[i].CecoId = detalle[i].CecoId.id;
                  }
                }

                if (detalle[i].ProyectoId != null) {
                  if (
                    typeof detalle[i].ProyectoId === "string" ||
                    detalle[i].ProyectoId instanceof String
                  ) {
                    detalle[i].ProyectoId = detalle[i].ProyectoId;
                  } else {
                    detalle[i].ProyectoId = detalle[i].ProyectoId.id;
                  }
                }
              }
            }
          }

          let solicitud = {
            Encabezado: encab,
            Detalle: detalle,
          };

          if (
            this.$route.params.solicitudId != undefined ||
            this.$route.params.solicitudId != null
          ) {
            this.getGUID();

            solicitud.Encabezado.Id = this.guid;

            for (let i = 0; i < solicitud.Detalle.length; i++) {
              solicitud.Detalle[i].EncabezadoId = this.guid;
            }
          }

          // console.log(JSON.stringify(solicitud));
          this.enviarSolicitud(solicitud);
        } else {
          if (this.tipoSol.id == "Rendicion") {
            if (this.lstDetalle.some((x) => x.TipoDoc == null)) {
              this.$swal.fire({
                icon: "warning",
                title: "Debe seleccionar un tipo de documento",
                showConfirmButton: true,
              });
              return;
            }

            if (this.lstDetalle.some((x) => x.FechaDoc == null)) {
              this.$swal.fire({
                icon: "warning",
                title: "Debe seleccionar una fecha de documento",
                showConfirmButton: true,
              });
              return;
            }

            if (
              this.lstDetalle.some(
                (x) =>
                  x.NroDoc == null &&
                  x.TipoDoc != "Sin Documento" &&
                  x.TipoDoc != "Vale"
              )
            ) {
              this.$swal.fire({
                icon: "warning",
                title: "Debe ingresar un numero de documento",
                showConfirmButton: true,
              });
              return;
            }

            if (
              this.lstDetalle.some(
                (x) => x.ProveedorId == null && x.TipoDoc == "Factura"
              )
            ) {
              this.$swal.fire({
                icon: "warning",
                title: "Debe seleccionar un proveedor",
                showConfirmButton: true,
              });
              return;
            }

            if (
              this.lstDetalle.some(
                (x) => x.ProveedorId == null && x.TipoDoc == "B. Honorarios"
              )
            ) {
              this.$swal.fire({
                icon: "warning",
                title: "Debe seleccionar un proveedor",
                showConfirmButton: true,
              });
              return;
            }

            if (this.lstDetalle.some((x) => x.DescGasto == null)) {
              this.$swal.fire({
                icon: "warning",
                title: "Debe ingresar una descripción del gasto",
                showConfirmButton: true,
              });
              return;
            }

            if (this.lstDetalle.some((x) => x.Monto == null || x.Monto == 0)) {
              this.$swal.fire({
                icon: "warning",
                title: "Debe ingresar un monto",
                showConfirmButton: true,
              });
              return;
            }

            encab = {
              Id: this.guid,
              FechaSolicitud: this.date,
              SolicitantePorId: this.solPorId,
              SolicitantePorNombre: this.solPorNombre,
              CompanyId: this.empresaSel.id,
              DepartamentoId: this.departamentoSel.id,
              Observacion: this.obs,
              SolicitanteId: this.solicitanteSel.rut,
              SolicitanteNombre: this.solicitanteSel.fullName,
              Estado: "Creada",
              TipoSolicitudId: this.tipoSol.id,
            };

            detalle = this.lstDetalle;

            for (let i = 0; i < detalle.length; i++) {
              detalle[i].DeptoId = this.departamentoSel.id;
            }

            let solicitud = {
              Encabezado: encab,
              Detalle: detalle,
            };

            if (
              this.$route.params.solicitudId != undefined ||
              this.$route.params.solicitudId != null
            ) {
              this.getGUID();

              solicitud.Encabezado.Id = this.guid;

              for (let i = 0; i < solicitud.Detalle.length; i++) {
                solicitud.Detalle[i].EncabezadoId = this.guid;

                if (detalle[i].CecoId != null) {
                  if (
                    typeof detalle[i].CecoId === "string" ||
                    detalle[i].CecoId instanceof String
                  ) {
                    detalle[i].CecoId = detalle[i].CecoId;
                  } else {
                    detalle[i].CecoId = detalle[i].CecoId.id;
                  }
                }

                if (detalle[i].ProyectoId != null) {
                  if (
                    typeof detalle[i].ProyectoId === "string" ||
                    detalle[i].ProyectoId instanceof String
                  ) {
                    detalle[i].ProyectoId = detalle[i].ProyectoId;
                  } else {
                    detalle[i].ProyectoId = detalle[i].ProyectoId.id;
                  }
                }

                if (detalle[i].ProveedorId != null) {
                  if (
                    typeof detalle[i].ProveedorId === "string" ||
                    detalle[i].ProveedorId instanceof String
                  ) {
                    detalle[i].ProveedorId = detalle[i].ProveedorId;
                  } else {
                    detalle[i].ProveedorId = detalle[i].ProveedorId.id;
                  }
                }

                if (detalle[i].TipoDoc != null) {
                  if (
                    typeof detalle[i].TipoDoc === "string" ||
                    detalle[i].TipoDoc instanceof String
                  ) {
                    detalle[i].TipoDoc = detalle[i].TipoDoc;
                  } else {
                    detalle[i].TipoDoc = detalle[i].TipoDoc.id;
                  }
                }
              }
            }

            //console.log(JSON.stringify(solicitud));
            this.enviarSolicitud(solicitud);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    obtenerEmpleado: function (email) {
      try {
        this.isLoading = true;

        axios
          .get(
            store.state.url + "api/Empleado/GetEmpleadoXEmail?email=" + email,
            { headers: {} }
          )
          .then((response) => {
            if (response.status == 200) {
              this.solicitantePor = response.data;

              this.solPorId = this.solicitantePor.rut;
              this.solPorNombre = this.solicitantePor.fullName;
            } else {
              this.solPorId = "";
              this.solPorNombre = "";
              //console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    enviarSolicitud: async function (solicitud) {
      axios
        .post(
          store.state.url + "api/SolicitudCompra/PostSolicitudCompra",
          solicitud
        )
        .then(async (response) => {
          if (response.status == 200) {
            if (this.file.length > 0) {
              await this.submitFile(solicitud, "crear");
            }

            this.date = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10);

            // this.empresaSel = null;
            // this.departamentoSel = null;
            // this.vendorSel = null;
            // this.solicitanteSel = null;
            // this.obs = "";

            // this.lstItems = [];
            // this.lstDetalle = [];
            // this.file = {};

            // this.detContador = 1;
            // this.guid = this.getGUID();

            this.$swal.fire({
              icon: "success",
              title: "Solicitud creada exitosamente",
              showConfirmButton: false,
              timer: 2000,
            });

            await this.sendEmail(solicitud.Encabezado.Id);

            this.$router.push({
              name: "BuscaSolicitud",
            });

            // if (this.$cookies.get("username") != "") {
            //   this.obtenerEmpleado(this.$cookies.get("username"));
            //   //this.obtenerEmpleado("eherrera@oxxeanchile.cl");
            // }

            // if (
            //   this.$route.params.solicitudId != undefined ||
            //   this.$route.params.solicitudId != null
            // ) {
            //   this.$router.push({
            //     name: "SolCompra",
            //   });
            // }
          } else {
            console.log(response.statusText);
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => (this.isLoading = false));
    },

    guardarSolicitud: async function () {
      try {
        var encab = {};
        var detalle = [];

        this.isLoading = true;

        if (
          this.solPorId == null ||
          this.solPorId == "" ||
          this.solPorNombre == null ||
          this.solPorNombre == ""
        ) {
          this.$swal.fire({
            icon: "warning",
            title: "Debe haber un ingresado por",
            showConfirmButton: true,
          });
          return;
        }

        if (this.tipoSol.id == "Compra" || this.tipoSol.id == "Retiro Bodega") {
          encab = {
            Id: this.guid,
            FechaSolicitud: this.date,
            SolicitantePorId: this.solPorId,
            SolicitantePorNombre: this.solPorNombre,
            CompanyId: this.empresaSel != null ? this.empresaSel.id : null,
            DepartamentoId:
              this.departamentoSel != null ? this.departamentoSel.id : null,

            Observacion: this.obs,

            SolicitanteId:
              this.solicitanteSel != null ? this.solicitanteSel.rut : null,

            SolicitanteNombre:
              this.solicitanteSel != null ? this.solicitanteSel.fullName : null,

            // VendorId: this.vendorSel != null ? this.vendorSel.id : null,
            Estado: "Guardada",
            TipoSolicitudId: this.tipoSol.id,
            ProyectoId: this.proyectoSel != null ? this.proyectoSel.id : null,
            //CodigoBodega: this.codigoBodega != null ? this.codigoBodega : null,
          };

          detalle = this.lstDetalle;

          if (this.tipoSol.id == "Compra") {
            encab.VendorId = this.vendorSel != null ? this.vendorSel.id : null;
            encab.VendorNumber =
              this.vendorSel != null ? this.vendorSel.number : null;
            encab.PayToVendorId =
              this.vendorSel != null ? this.vendorSel.id : null;
            encab.PayToVendorNumber =
              this.vendorSel != null ? this.vendorSel.number : null;

            if (
              this.$route.params.solicitudId != undefined ||
              this.$route.params.solicitudId != null
            ) {
              for (let i = 0; i < detalle.length; i++) {
                // detalle[i].CecoId = detalle[i].CecoId.id;
                // detalle[i].ProyectoId = detalle[i].ProyectoId.id;
                // detalle[i].CodigoBodega =
                //   detalle[i].CodigoBodega != null
                //     ? detalle[i].CodigoBodega.id
                //     : null;

                if (detalle[i].CecoId != null) {
                  if (
                    typeof detalle[i].CecoId === "string" ||
                    detalle[i].CecoId instanceof String
                  ) {
                    detalle[i].CecoId = detalle[i].CecoId;
                  } else {
                    detalle[i].CecoId = detalle[i].CecoId.id;
                  }
                }

                if (detalle[i].ProyectoId != null) {
                  if (
                    typeof detalle[i].ProyectoId === "string" ||
                    detalle[i].ProyectoId instanceof String
                  ) {
                    detalle[i].ProyectoId = detalle[i].ProyectoId;
                  } else {
                    detalle[i].ProyectoId = detalle[i].ProyectoId.id;
                  }
                }

                if (detalle[i].CodigoBodega != null) {
                  if (
                    typeof detalle[i].CodigoBodega === "string" ||
                    detalle[i].CodigoBodega instanceof String
                  ) {
                    detalle[i].CodigoBodega = detalle[i].CodigoBodega;
                  } else {
                    detalle[i].CodigoBodega = detalle[i].CodigoBodega.id;
                  }
                }
              }
            }
          }

          if (this.tipoSol.id == "Retiro Bodega") {
            encab.CodigoBodega =
              this.codigoBodega != null ? this.codigoBodega.id : null;

            if (
              this.$route.params.solicitudId != undefined ||
              this.$route.params.solicitudId != null
            ) {
              for (let i = 0; i < detalle.length; i++) {
                if (detalle[i].CecoId != null) {
                  if (
                    typeof detalle[i].CecoId === "string" ||
                    detalle[i].CecoId instanceof String
                  ) {
                    detalle[i].CecoId = detalle[i].CecoId;
                  } else {
                    detalle[i].CecoId = detalle[i].CecoId.id;
                  }
                }

                if (detalle[i].ProyectoId != null) {
                  if (
                    typeof detalle[i].ProyectoId === "string" ||
                    detalle[i].ProyectoId instanceof String
                  ) {
                    detalle[i].ProyectoId = detalle[i].ProyectoId;
                  } else {
                    detalle[i].ProyectoId = detalle[i].ProyectoId.id;
                  }
                }
              }
            }
          }

          let solicitud = {
            Encabezado: encab,
            Detalle: detalle,
          };

          if (
            this.$route.params.solicitudId != undefined ||
            this.$route.params.solicitudId != null
          ) {
            this.getGUID();

            solicitud.Encabezado.Id = this.guid;

            for (let i = 0; i < solicitud.Detalle.length; i++) {
              solicitud.Detalle[i].EncabezadoId = this.guid;
            }
          }

          //console.log(JSON.stringify(solicitud));
          this.enviarSolicitudGuardada(solicitud);
        } else {
          if (this.tipoSol.id == "Rendicion") {
            encab = {
              Id: this.guid,
              FechaSolicitud: this.date,
              SolicitantePorId: this.solPorId,
              SolicitantePorNombre: this.solPorNombre,
              CompanyId: this.empresaSel != null ? this.empresaSel.id : null,
              DepartamentoId:
                this.departamentoSel != null ? this.departamentoSel.id : null,
              Observacion: this.obs,
              SolicitanteId:
                this.solicitanteSel != null ? this.solicitanteSel.rut : null,
              SolicitanteNombre:
                this.solicitanteSel != null
                  ? this.solicitanteSel.fullName
                  : null,
              Estado: "Guardada",
              TipoSolicitudId: this.tipoSol.id,
              ProyectoId: this.proyectoSel != null ? this.proyectoSel.id : null,
            };

            detalle = this.lstDetalle;

            if (this.departamentoSel != null) {
              for (let i = 0; i < detalle.length; i++) {
                detalle[i].DeptoId = this.departamentoSel.id;

                if (
                  this.$route.params.solicitudId != undefined ||
                  this.$route.params.solicitudId != null
                ) {
                  for (let i = 0; i < detalle.length; i++) {
                    // detalle[i].CecoId = detalle[i].CecoId.id;
                    // detalle[i].ProyectoId = detalle[i].ProyectoId.id;

                    if (detalle[i].CecoId != null) {
                      if (
                        typeof detalle[i].CecoId === "string" ||
                        detalle[i].CecoId instanceof String
                      ) {
                        detalle[i].CecoId = detalle[i].CecoId;
                      } else {
                        detalle[i].CecoId = detalle[i].CecoId.id;
                      }
                    }

                    if (detalle[i].ProyectoId != null) {
                      if (
                        typeof detalle[i].ProyectoId === "string" ||
                        detalle[i].ProyectoId instanceof String
                      ) {
                        detalle[i].ProyectoId = detalle[i].ProyectoId;
                      } else {
                        detalle[i].ProyectoId = detalle[i].ProyectoId.id;
                      }
                    }

                    if (detalle[i].ProveedorId != null) {
                      if (
                        typeof detalle[i].ProveedorId === "string" ||
                        detalle[i].ProveedorId instanceof String
                      ) {
                        detalle[i].ProveedorId = detalle[i].ProveedorId;
                      } else {
                        detalle[i].ProveedorId = detalle[i].ProveedorId.id;
                      }
                    }

                    if (detalle[i].TipoDoc != null) {
                      if (
                        typeof detalle[i].TipoDoc === "string" ||
                        detalle[i].TipoDoc instanceof String
                      ) {
                        detalle[i].TipoDoc = detalle[i].TipoDoc;
                      } else {
                        detalle[i].TipoDoc = detalle[i].TipoDoc.id;
                      }
                    }
                  }
                }
              }
            }

            let solicitud = {
              Encabezado: encab,
              Detalle: detalle,
            };

            if (
              this.$route.params.solicitudId != undefined ||
              this.$route.params.solicitudId != null
            ) {
              this.getGUID();

              solicitud.Encabezado.Id = this.guid;

              for (let i = 0; i < solicitud.Detalle.length; i++) {
                solicitud.Detalle[i].EncabezadoId = this.guid;
              }
            }

            //console.log(JSON.stringify(solicitud));
            this.enviarSolicitudGuardada(solicitud);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    enviarSolicitudGuardada: async function (solicitud) {
      this.isLoading = true;

      axios
        .post(
          store.state.url + "api/SolicitudCompra/PostSolicitudCompraGuardada",
          solicitud
        )
        .then(async (response) => {
          if (response.status == 200) {
            if (this.file.length > 0) {
              await this.submitFile(solicitud, "guardar");
            }

            this.date = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10);

            this.total = 0;

            this.$swal.fire({
              icon: "success",
              title: "Solicitud Guardada Exitosamente",
              showConfirmButton: false,
              timer: 2000,
            });

            this.$router.push({
              name: "BuscaSolicitud",
            });
          } else {
            console.log(response.statusText);
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
        })
        .finally(() => (this.isLoading = false));
    },

    submitFile: async function (solicitud, tipo) {
      const files = new FormData();

      for (let i = 0; i < this.file.length; i++) {
        files.append("files", this.file[i]);
      }
      //files.append("files", this.file);
      const headers = { "Content-Type": "multipart/form-data" };

      await axios
        .post(
          store.state.url + "api/SolicitudCompra/UploadMultipleFile",
          files,
          {
            headers,
          }
        )
        .then(async (res) => {
          if (res.status == 200) {
            await this.postFiles(res.data, solicitud.Encabezado.Id);
            console.log(tipo);

            /*             if (tipo == "guardar") {
              this.enviarSolicitudGuardada(solicitud);
            } else {
              this.enviarSolicitud(solicitud);
            } */
          } else {
            console.log("Error al cargar archivo, status code:" + res.status);
          }
        })
        .finally(() => (this.isLoading = false));
    },

    postFiles: async function (files, solId) {
      await axios
        .post(
          store.state.url +
            "api/SolicitudCompra/PostFiles?solicitudId=" +
            solId,
          files,
          { headers: {} }
        )
        .then((response) => {
          if (response.status == 200) {
            console.log("Archivos subidos correctamente");
          } else {
            console.log(response.statusText);
          }
        })
        .catch(function (error) {
          console.log(error.message);
        });
    },

    showDeleteDialog(item) {
      this.itemToDelete = item;
      this.dialogDelete = !this.dialogDelete;
    },

    deleteMaterial: function (item) {
      const index = this.lstDetalle.indexOf(item);
      this.lstDetalle.splice(index, 1);

      this.dialogDelete = !this.dialogDelete;

      for (let i = 0; i < this.lstDetalle.length; i++) {
        this.lstDetalle[i].id = i + 1;
      }

      this.detContador = this.lstDetalle.length + 1;
    },

    CalcularTotal: function (item) {
      item.Total = item.Cantidad * item.Precio;
    },

    RendicionTotal: function (item) {
      //console.log(item.Monto);

      //suma de todos los montos
      this.total = this.lstDetalle.reduce(
        (acc, item) => acc + parseFloat(item.Monto),
        0
      );
    },

    formatMoney: function (value) {
      return new Intl.NumberFormat("es-CL", {
        style: "currency",
        currency: "CLP",
      }).format(value);
    },

    getGUID: function () {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },

    getSolicitudCompra: function () {
      try {
        axios
          .get(
            store.state.url +
              "api/SolicitudCompra/GetSolicitudCompra?solicitudId=" +
              this.$route.params.solicitudId
          )
          .then(async (response) => {
            if (response.status == 200) {
              var solicitud = response.data;

              this.idSolicitud = solicitud.encabezado.id;
              this.tipoSol.id = solicitud.encabezado.tipoSolicitudId;
              this.date = solicitud.encabezado.fechaSolicitud.substr(0, 10);

              await this.getCompanies();

              if (solicitud.encabezado.companyId != null) {
                this.empresaSel = this.lstEmpresas.find(
                  (r) => r.id == solicitud.encabezado.companyId
                );
              }

              if (this.empresaSel != null) {
                await this.getDepartamentos();
                await this.getCeco();
                await this.getBodegas();
                await this.getVendors();
                await this.getProyectos();
              }

              await this.getSolicitantes();

              if (solicitud.encabezado.departamentoId != null) {
                this.departamentoSel = this.lstDepartamento.find(
                  (d) => d.id == solicitud.encabezado.departamentoId
                );
              }

              this.solicitanteSel = this.lstSolicitantes.find(
                (s) => s.rut == solicitud.encabezado.solicitanteId
              );

              this.obs = solicitud.encabezado.observacion;

              this.vendorSel = this.lstVendors.find(
                (v) => v.id == solicitud.encabezado.vendorId
              );

              if (this.tipoSol.id == "Retiro Bodega") {
                this.codigoBodega = this.lstBodegas.find(
                  (b) => b.id == solicitud.encabezado.codigoBodega
                );

                for (let i = 0; i < solicitud.detalle.length; i++) {
                  solicitud.detalle[i].Tipo = solicitud.detalle[i].tipo;
                  solicitud.detalle[i].Material = solicitud.detalle[i].material;
                  solicitud.detalle[i].CodigoMaterial =
                    solicitud.detalle[i].codigoMaterial;

                  solicitud.detalle[i].Cantidad = solicitud.detalle[i].cantidad;
                  solicitud.detalle[i].Unidad = solicitud.detalle[i].unidad;

                  if (solicitud.detalle[i].cecoId != null) {
                    solicitud.detalle[i].CecoId = this.lstCeco.find(
                      (c) => c.id == solicitud.detalle[i].cecoId
                    );
                  }

                  solicitud.detalle[i].ProyectoId = this.lstProyectos.find(
                    (p) => p.id == solicitud.detalle[i].proyectoId
                  );

                  solicitud.detalle[i].RetiroBodega =
                    solicitud.detalle[i].retiroBodega;
                  solicitud.detalle[i].OTMantencion =
                    solicitud.detalle[i].otMantencion;
                }
              }

              if (this.tipoSol.id == "Rendicion") {
                for (let i = 0; i < solicitud.detalle.length; i++) {
                  solicitud.detalle[i].TipoDoc = this.lstTipoDoc.find(
                    (t) => t.id == solicitud.detalle[i].tipoDoc
                  );

                  solicitud.detalle[i].NroDoc = solicitud.detalle[i].nroDoc;

                  if (solicitud.detalle[i].fechaDoc != null) {
                    solicitud.detalle[i].FechaDoc = solicitud.detalle[
                      i
                    ].fechaDoc.substr(0, 10);
                  }

                  if (solicitud.detalle[i].proveedorId != null) {
                    solicitud.detalle[i].ProveedorId = this.lstVendors.find(
                      (v) => v.id == solicitud.detalle[i].proveedorId
                    );
                  }

                  solicitud.detalle[i].Material = solicitud.detalle[i].material;
                  solicitud.detalle[i].DescGasto =
                    solicitud.detalle[i].descGasto;

                  if (solicitud.detalle[i].cecoId != null) {
                    solicitud.detalle[i].CecoId = this.lstCeco.find(
                      (c) => c.id == solicitud.detalle[i].cecoId
                    );
                  }

                  if (solicitud.detalle[i].proyectoId != null) {
                    solicitud.detalle[i].ProyectoId = this.lstProyectos.find(
                      (p) => p.id == solicitud.detalle[i].proyectoId
                    );
                  }

                  solicitud.detalle[i].Monto = solicitud.detalle[i].monto;
                  this.RendicionTotal();
                }
              }

              if (this.tipoSol.id == "Compra") {
                // this.codigoBodega = this.lstBodegas.find(
                //   (b) => b.id == solicitud.encabezado.codigoBodega
                // );

                for (let i = 0; i < solicitud.detalle.length; i++) {
                  solicitud.detalle[i].Tipo = solicitud.detalle[i].tipo;
                  solicitud.detalle[i].Material = solicitud.detalle[i].material;
                  solicitud.detalle[i].CodigoMaterial =
                    solicitud.detalle[i].codigoMaterial;

                  solicitud.detalle[i].Cantidad = solicitud.detalle[i].cantidad;
                  solicitud.detalle[i].Unidad = solicitud.detalle[i].unidad;

                  if (solicitud.detalle[i].cecoId != null) {
                    solicitud.detalle[i].CecoId = this.lstCeco.find(
                      (c) => c.id == solicitud.detalle[i].cecoId
                    );
                  }

                  solicitud.detalle[i].ProyectoId = this.lstProyectos.find(
                    (p) => p.id == solicitud.detalle[i].proyectoId
                  );

                  solicitud.detalle[i].RetiroBodega =
                    solicitud.detalle[i].retiroBodega;
                  solicitud.detalle[i].OTMantencion =
                    solicitud.detalle[i].otMantencion;

                  if (solicitud.detalle[i].vendorId != null) {
                    solicitud.detalle[i].VendorId = this.lstVendors.find(
                      (v) => v.id == solicitud.detalle[i].vendorId
                    );
                  }

                  if (solicitud.detalle[i].codigoBodega != null) {
                    solicitud.detalle[i].CodigoBodega = this.lstBodegas.find(
                      (b) => b.id == solicitud.detalle[i].codigoBodega
                    );
                  }

                  solicitud.detalle[i].Precio = solicitud.detalle[i].precio;
                  solicitud.detalle[i].Total = solicitud.detalle[i].total;
                }
              }

              this.lstDetalle = solicitud.detalle;
              this.detContador = this.lstDetalle.length + 1;
            }
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => (this.isLoading = false));
      } catch (error) {
        console.log(error);
      }
    },

    selectCompany: async function () {
      try {
        this.isLoading = true;
        if (typeof this.empresaSel != "string" && this.empresaSel != null) {
          //console.log(JSON.stringify(this.empresaSel));

          await this.getDepartamentos();
          await this.getVendors();
          await this.getProyectos();
          await this.getCeco();
          await this.getBodegas();
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    sendEmail: async function (idSol) {
      try {
        await this.refreshToken()
          .then(async () => {
            var token = this.$cookies.get("token");

            await axios
              .post(
                store.state.url +
                  "api/SolicitudCompra/SendEmail?idSol=" +
                  idSol,
                token,
                { headers: { "Content-Type": "application/json" } }
              )
              .then((response) => {
                if (response.status == 200) {
                  console.log(response.data);
                } else {
                  console.log(response.statusText);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },

    refreshToken: async function () {
      try {
        await this.$msalInstance.initialize();
        //await this.$msalInstance.handleRedirectPromise();

        var redirectResponse = await this.$msalInstance.handleRedirectPromise();
        if (redirectResponse !== null) {
          //console.log(redirectResponse.accessToken);
          this.$cookies.set("token", redirectResponse.accessToken);
        } else {
          var myAcccount = this.$msalInstance.getAllAccounts();

          await this.$msalInstance
            .acquireTokenSilent({
              scopes: ["User.Read", "Mail.Send"],
              account: myAcccount[0],
            })
            .then((response) => {
              this.$cookies.set("token", response.accessToken);
              //console.log(response);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } catch (error) {
        console.log(error);
      }
    },

    addFiles: async function () {
      for (var f of this.selectedFiles) {
        this.file.push(f);
      }

      this.selectedFiles = [];
    },

    cambioRetBodega: function () {
      if (this.tipoSol.id == "Retiro Bodega") {
        for (var detRet of this.lstDetalle) {
          detRet.RetiroBodega = true;
        }
      } else {
        for (var det of this.lstDetalle) {
          det.RetiroBodega = false;
        }
      }
    },
  },

  created: async function () {
    const azureService = new AzureService();

    this.$msalInstance = new PublicClientApplication(
      azureService.getMsalConfig().value
    );

    store.state.titulo = "Crear Solicitud";

    //await this.getCompanies();
    await this.getSolicitantes();

    if (this.$cookies.get("username") != "") {
      this.obtenerEmpleado(this.$cookies.get("username"));
      // this.obtenerEmpleado("eherrera@oxxeanchile.cl");
    }

    var correo = await this.sendEmail("65f88514-bbc5-4d4d-b7cd-08026291492d");
    console.log(correo);

    this.guid = this.getGUID();

    if (
      this.$route.params.solicitudId != undefined ||
      this.$route.params.solicitudId != null
    ) {
      this.getSolicitudCompra();
    } else {
      await this.getCompanies();
    }
  },
};
</script>

<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2>Agregar Roles</h2>

    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label>Rol:</label>

        <v-combobox
          v-model="rolSel"
          :items="lstRoles"
          variant="solo"
          hide-details
          pa-1
          clearable
          ma-0
          item-title="nombre"
          item-value="id"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.nombre }}</div>
          </template>
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Empleado:</label>

        <v-combobox
          v-model="empleadoSel"
          :items="lstEmpleados"
          variant="solo"
          hide-details
          pa-1
          clearable
          ma-0
          item-title="rutDisplayName"
          item-value="rut"
        >
          <template slot="item" slot-scope="data">
            <div>{{ data.item.rutDisplayName }}</div>
          </template>
        </v-combobox>
      </div>

      <v-btn
        @click="addRol"
        style="
          background-color: #a3a3a3;
          padding: 15px;
          border-radius: 15px;
          margin-top: 2.4em;
          color: white;
          display: flex;
        "
        dark
        :disabled="!empleadoSel || !rolSel"
      >
        Guardar
      </v-btn>
    </div>

    <br />
    <h2>Roles</h2>

    <v-text-field
      v-model="search"
      label="Buscar por empleado, email o rol"
      class="ma-2 w-50"
      density="compact"
      prepend-inner-icon="mdi-magnify"
      single-line
      variant="solo"
      hide-details
    ></v-text-field>

    <v-data-table :headers="headers" :items="lstTablaRoles" :search="search">
      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-update" color="red" @click="showUpdateDialog(item)" />
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>
  </div>

  <v-dialog v-model="dialogDelete" persistent width="auto">
    <v-card>
      <v-card-title class="text-h5"> Borrar Rol </v-card-title>
      <v-card-text> Esta seguro que desea borrar este rol? </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogDelete = false">No</v-btn>
        <v-btn color="primary" text @click="deleteRolTable(rolToDelete)"
          >Si</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogUpdate" persistent width="1024">
    <v-card>
      <v-card-title>
        <span class="text-h5">Actualizar Rol</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                :items="lstRoles"
                variant="solo"
                v-model="roleSelUpd"
                hide-details
                pa-1
                ma-0
                item-title="nombre"
                item-value="id"
              >
                <template slot="item" slot-scope="data">
                  <div>{{ data.item.nombre }}</div>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" sm="6">
              <v-combobox
                :items="lstEmpleados"
                v-model="emplSelUpd"
                variant="solo"
                hide-details
                pa-1
                ma-0
                item-title="rutDisplayName"
                item-value="rut"
              >
                <template slot="item" slot-scope="data">
                  <div>{{ data.item.rutDisplayName }}</div>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue-darken-1"
          variant="text"
          @click="dialogUpdate = false"
        >
          Cerrar
        </v-btn>
        <v-btn
          :disabled="!emplSelUpd || !roleSelUpd"
          color="blue-darken-1"
          variant="text"
          @click="updateRol(rolToUpdate)"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "../store/index.js";
import axios from "axios";

export default {
  name: "RolMantenedor",
  components: {
    //Loading,
  },
  data() {
    return {
      lstEmpleados: [],
      empleadoSel: null,

      lstRoles: [
        { id: "Administrador", nombre: "Administrador" },
        { id: "Aprobador", nombre: "Aprobador" },
        { id: "Autorizador", nombre: "Autorizador" },
        { id: "Bodega", nombre: "Bodega" },
        { id: "Comprador", nombre: "Comprador" },
        { id: "Contabilidad", nombre: "Contabilidad" },
      ],
      rolSel: null,

      lstTablaRoles: [],
      headers: [
        { title: "Empleado", key: "nombre", align: "start" },
        { title: "Email", key: "email", align: "start" },
        { title: "Rol", key: "rolNombre", align: "start" },
        { title: "", key: "actions", align: "start", width: "100px" },
      ],

      dialogDelete: false,
      dialogUpdate: false,

      rolToDelete: null,
      rolToUpdate: null,

      emplSelUpd: null,
      rolSelUpd: null,
      search: "",
    };
  },
  methods: {
    getSolicitantes: async function () {
      try {
        await axios
          .get(store.state.url + "api/Empleado/GetEmpleados", { headers: {} })
          .then((response) => {
            if (response.status == 200) {
              this.lstEmpleados = response.data;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    getRoles: async function () {
      try {
        await axios
          .get(store.state.url + "api/Rol/GetRoles", { headers: {} })
          .then((response) => {
            if (response.status == 200) {
              this.lstTablaRoles = response.data;
              //console.log(this.lstTablaRoles);
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    addRol: async function () {
      try {
        var rol = {
          nombre: this.empleadoSel.fullName,
          email: this.empleadoSel.email,
          rolNombre: this.rolSel.id,
        };

        await axios
          .post(store.state.url + "api/Rol/AddRol", rol, {
            headers: {},
          })
          .then((response) => {
            if (response.status == 200) {
              this.lstTablaRoles.unshift(rol);
              console.log("Rol agregado");

              this.$swal.fire({
                icon: "success",
                title: "Rol Creado Exitosamente",
                showConfirmButton: false,
                timer: 2000,
              });

              this.empleadoSel = null;
              this.rolSel = null;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    deleteRol: async function (item) {
      try {
        await axios
          .delete(store.state.url + "api/Rol/DeleteRol?id=" + item.id, {
            headers: {},
          })
          .then((response) => {
            if (response.status == 200) {
              //this.getRoles();
              console.log("Rol eliminado");
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    updateRol: async function (item) {
      try {
        var rol = {
          id: item.id,
          nombre: this.emplSelUpd.fullName,
          email: this.emplSelUpd.email,
          rolNombre: this.roleSelUpd.id,
        };

        await axios
          .put(store.state.url + "api/Rol/UpdateRol", rol, {
            headers: {},
          })
          .then((response) => {
            if (response.status == 200) {
              console.log("Rol actualizado");

              this.$swal.fire({
                icon: "success",
                title: "Rol Actualizado Exitosamente",
                showConfirmButton: false,
                timer: 2000,
              });

              this.dialogUpdate = !this.dialogUpdate;
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error.message);
          });
      } catch (error) {
        console.log(error);
      }
    },

    deleteRolTable: function (item) {
      this.deleteRol(item).then(() => {
        const index = this.lstTablaRoles.indexOf(item);
        this.lstTablaRoles.splice(index, 1);

        this.dialogDelete = !this.dialogDelete;
      });
    },

    showDeleteDialog: function (item) {
      this.rolToDelete = item;
      this.dialogDelete = !this.dialogDelete;
    },

    showUpdateDialog: function (item) {
      this.rolToUpdate = item;

      this.emplSelUpd = this.lstEmpleados.find((x) => x.email == item.email);
      this.roleSelUpd = this.lstRoles.find((x) => x.id == item.rolNombre);

      this.dialogUpdate = !this.dialogUpdate;
    },
  },

  async created() {
    try {
      store.state.titulo = "Mantenedor Roles";

      await this.getSolicitantes();
      await this.getRoles();
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
